export const PROFILE = '/users/profile'
export const LOGIN = '/auth/login'
export const LOGIN_ON_BEHALF = '/auth/login/on-behalf'
export const LOGOUT = '/logout'
export const GOOGLE_AUTH = '/auth/google'
export const REGISTER = '/auth/register'
export const PHOTOGRAPHER_REGISTER = '/auth/register/photographer'
export const GOOGLE_REGISTER = '/auth/google/register'
export const FORGOT_PASSWORD = 'auth/forgotPassword'
export const RESET_PASSWORD = 'auth/resetPassword'
export const updateUserRoleById = (userId: string) => {
  return `/users/${userId}/roles`
}
export const requestPhotographerRole = (userId: string) => {
  return `/users/${userId}/request-photographer-role`
}

export const requestEmailVerification = (userId: string) => {
  return `users/${userId}/request-email-verification`
}
export const getStripeOnboardingUrl = (userId: string) => {
  return `/users/${userId}/onboarding-url`
}
export const getStripeLoginUrl = (userId: string) => {
  return `/users/${userId}/stripe-login-url`
}
export const VALIDATE_MERCADOPAGO_CREDENTIALS = '/mercadopago/credentials'
export const VERIFY_USER = '/auth/verify-user'
