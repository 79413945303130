import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import InputWrapper from '../../../components/UI/Forms/InputWrapper'
import LoginRegisterStore from '../LoginRegisterStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard, faLock, faSquareEnvelope, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { getFeatureFlagValue } from 'shared/utility'
import StoresContext from '../../../providers/storesContext'
import { FeatureFlags } from 'config/constants/featureFlags'

const SIGN_IN_MODE = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
}

type LoginRegisterFormProps = {
  loginRegisterStore: LoginRegisterStore
  isLogin: string
}

const LoginRegisterForm = ({ loginRegisterStore, isLogin }: LoginRegisterFormProps) => {
  const { t } = useTranslation()
  const [isAdminEmail, setIsAdminEmail] = useState(false)
  const { featureFlagsStore } = useContext(StoresContext)!
  const handleChangePassword = (val: string) => loginRegisterStore.changePassword(val)
  const handleChangeLoginPassword = (val: string) => loginRegisterStore.changeLoginPassword(val)
  const handleChangeFirstName = (val: string) => loginRegisterStore.changeFirstName(val)
  const handleChangeLastName = (val: string) => loginRegisterStore.changeLastName(val)
  const handleChangeClientEmail = (val: string) => loginRegisterStore.changeClientEmail(val)
  const handleChangeLoginOnBehalf = (val: boolean) => loginRegisterStore.setLoginOnBehalf(val)
  const handleChangeReferredBy = (val: string) => loginRegisterStore.changeReferredBy(val)
  const {
    clientEmail,
    adminEmail,
    password,
    loginPassword,
    firstName,
    lastName,
    loginOnBehalf,
    referredBy,
  } = loginRegisterStore

  const photographerReferralEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.PHOTOGRAPHER_REFERRAL,
    false
  )

  const handleChangeEmail = (val: string) => {
    const isAdminEmail = val.endsWith('@spotted.com.uy') || val.endsWith('@lumepic.com')
    if (!loginOnBehalf) {
      loginRegisterStore.changeClientEmail(val)
    }
    setIsAdminEmail(isAdminEmail)
    if (isAdminEmail) {
      loginRegisterStore.changeAdminEmail(val)
    }
  }

  useEffect(() => {}, [loginRegisterStore.clientEmail])

  const emailIcon = (
    <span className="flex items-center pt-1 w-8 h-8">
      <FontAwesomeIcon icon={faSquareEnvelope} className="text-2xl" />
    </span>
  )
  const passwordIcon = (
    <span
      className="flex items-center mb-1 ml-px w-5 h-5 rounded-sm"
      style={{ backgroundColor: 'rgb(99, 99, 99)' }}
    >
      <FontAwesomeIcon icon={faLock} className="pl-1 pr-px pb-px text-sm text-white" />
    </span>
  )
  const userIcon = (
    <span
      className="flex items-center mb-1 pl-px pr-0.5 h-5 rounded-sm"
      style={{ backgroundColor: 'rgb(99, 99, 99)' }}
    >
      <FontAwesomeIcon icon={faIdCard} className="pl-0.5 pr-px pb-px text-sm text-white" />
    </span>
  )

  const referredByIcon = (
    <span
      className="flex items-center mb-1 pl-px pr-0.5 h-5 rounded-sm"
      style={{ backgroundColor: 'rgb(99, 99, 99)' }}
    >
      <FontAwesomeIcon icon={faUserGroup} className="pl-0.5 pr-px pb-px text-sm text-white" />
    </span>
  )

  return (
    <>
      {isLogin === SIGN_IN_MODE.LOGIN ? (
        <div className="flex flex-col gap-3">
          {isAdminEmail && loginOnBehalf ? (
            <InputWrapper
              onChange={handleChangeEmail}
              inputStore={adminEmail}
              extraClassNames="pl-10"
              placeholder={t('Email').toString()}
              fixedunit={emailIcon}
            />
          ) : (
            <InputWrapper
              onChange={handleChangeEmail}
              inputStore={clientEmail}
              extraClassNames="pl-10"
              placeholder={t('Email').toString()}
              fixedunit={emailIcon}
            />
          )}

          <InputWrapper
            onChange={handleChangeLoginPassword}
            inputStore={loginPassword}
            placeholder={t('Password').toString()}
            fixedunit={passwordIcon}
            extraClassNames="pl-10"
            password
          />

          {isAdminEmail && (
            <>
              <label className="inline-flex items-center mt-3">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={loginOnBehalf}
                  onChange={(e) => handleChangeLoginOnBehalf(e.target.checked)}
                />
                <span className="ml-2">{t('Login on behalf')}</span>
              </label>
              {loginOnBehalf && (
                <InputWrapper
                  onChange={handleChangeClientEmail}
                  inputStore={clientEmail}
                  placeholder={t('Client Email').toString()}
                  extraClassNames="pl-10"
                  fixedunit={emailIcon}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-3">
            <div className="flex gap-2">
              <InputWrapper
                onChange={handleChangeFirstName}
                inputStore={firstName}
                placeholder={t('First name').toString()}
                fixedunit={userIcon}
                extraClassNames="pl-10"
              />

              <InputWrapper
                onChange={handleChangeLastName}
                inputStore={lastName}
                placeholder={t('Last name').toString()}
                fixedunit={userIcon}
                extraClassNames="pl-10"
              />
            </div>

            <InputWrapper
              onChange={handleChangeEmail}
              inputStore={clientEmail}
              placeholder={t('Email').toString()}
              fixedunit={emailIcon}
              extraClassNames="pl-10"
            />

            <InputWrapper
              onChange={handleChangePassword}
              inputStore={password}
              placeholder={t('Password').toString()}
              fixedunit={passwordIcon}
              extraClassNames="pl-10"
              password={true}
            />

            {photographerReferralEnabled && (
              <InputWrapper
                onChange={handleChangeReferredBy}
                inputStore={referredBy}
                value={referredBy.value}
                placeholder={t('Referred by').toString()}
                fixedunit={referredByIcon}
                extraClassNames="pl-10"
                disabled={referredBy.isDisabled}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default observer(LoginRegisterForm)
