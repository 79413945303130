import React, { useState, useEffect, useRef } from 'react'
import Masonry from '@mui/lab/Masonry'
import { Photograph } from 'shared/models/Photograph'
import GallerySinglePhoto from '../DetailsPhotoGallery/GallerySinglePhoto'
import SkeletonLoaderMasonryGallery from '../Loaders/Skeleton/SkeletonLoaderMasonryGallery'
import AlbumDetailsStore from 'components/AlbumDetails/AlbumDetailsStore'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
type BatchedMasonryGalleryProps = {
  photographs: Photograph[]
  setPhotograph: (photographId: string) => void
  downloadFreePhotograph: (photographId: string) => void
  store: EventDetailsStore | AlbumDetailsStore
}

const BatchedMasonryGallery = ({
  photographs,
  setPhotograph,
  downloadFreePhotograph,
  store,
}: BatchedMasonryGalleryProps) => {
  const [pages, setPages] = useState<Photograph[][]>([])
  const [isLoading, setIsLoading] = useState(true)
  const prevPhotographsLength = useRef(0)

  useEffect(() => {
    setIsLoading(true)

    if (photographs.length < prevPhotographsLength.current) {
      setPages([])
    }

    const newPhotographs = photographs.slice(prevPhotographsLength.current)

    if (newPhotographs.length > 0) {
      setPages((prev) => [...prev, newPhotographs])
    }

    setIsLoading(false)
    prevPhotographsLength.current = photographs.length
  }, [photographs])

  if (isLoading && pages.length === 0) {
    return <SkeletonLoaderMasonryGallery />
  }

  return (
    <div className="w-full">
      {pages.map((pagePhotographs, pageIndex) => (
        <Masonry
          key={pageIndex}
          columns={{ xs: 2, sm: 3, md: 4 }}
          spacing={0.5}
          className="w-full"
          defaultColumns={4}
        >
          {pagePhotographs.map((photograph) => (
            <GallerySinglePhoto
              key={photograph.id}
              photograph={photograph}
              setPhotograph={setPhotograph}
              downloadFreePhotograph={downloadFreePhotograph}
              store={store}
            />
          ))}
        </Masonry>
      ))}
      {isLoading && (
        <div className="flex justify-center py-4">
          <SkeletonLoaderMasonryGallery />
        </div>
      )}
    </div>
  )
}

export default BatchedMasonryGallery
