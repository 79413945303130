export const FifteenMegabytesInBytes = 15000000 // 15 * 1000 * 1000 (Decimal)
export const FiveDaysInMilliseconds = 432000000 // 5 * 24 * 60 * 60 * 1000
export const MaxPhotosForPackageWithTextTag = 20
export const MaxPhotographersPerPackage = 40
export const ZapierBotIdEn = 'cm67wsebn0046wlja18qjvd2c'
export const ZapierBotIdEs = 'cm5yhai0p000963986f0cyedh'

export const ApplicationHeaders = {
  Password: 'x-password',
}
