import React, { useContext } from 'react'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import { observer } from 'mobx-react'
import axios from 'services/axios'
import { SetPageTitle } from 'shared/utility'
import RewardsSection from './components/RewardsSection'
import StoresContext from 'providers/storesContext'

const MyRewards = observer(() => {
  SetPageTitle('My Rewards')
  const { authStore } = useContext(StoresContext)!
  const user = authStore.getLoggedUser()

  return (
    <div className="container mx-auto px-4 py-8 bg-bg_details min-h-screen">
      <h2 className="mb-8 text-3xl sm:text-4xl text-lumepic-light_black font-bold">My Rewards</h2>
      <RewardsSection
        clients={user.clients}
        rewardsAmount={500}
        totalRewards={750}
        fidelityCodeEarnings={100}
      />
    </div>
  )
})

export default withErrorHandler(MyRewards, axios)
