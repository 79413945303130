import React, { useEffect } from 'react'
import { Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Photograph } from 'shared/models/Photograph'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import Spinner from 'components/UI/Spinner'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import AlbumDetailsStore from 'components/AlbumDetails/AlbumDetailsStore'
import { isMobileDevice } from 'shared/utility'

type GallerySinglePhotoProps = {
  photograph: Photograph
  setPhotograph: (photographId: string) => void
  downloadFreePhotograph: (photographId: string) => void
  store: AlbumDetailsStore | EventDetailsStore
}

const GallerySinglePhoto = ({
  photograph,
  setPhotograph,
  downloadFreePhotograph,
  store,
}: GallerySinglePhotoProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (!store.isLoadingFreePhotograph && store.photographDownloadResult) {
      const { success, error } = store.photographDownloadResult
      if (success) {
        const isMobile = isMobileDevice()
        if (!isMobile) {
          toast.success(t('Photograph downloaded successfully'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      } else if (error && error !== 'AbortError') {
        toast.error(t('Error downloading photograph: ') + error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
        })
      }
      store.resetPhotographDownloadResult()
    }
  }, [store.isLoadingFreePhotograph, store.photographDownloadResult])

  const handleDownload = (e: React.MouseEvent) => {
    e.stopPropagation()
    downloadFreePhotograph(photograph.id)
  }

  return (
    <div key={photograph.id} className="relative group cursor-pointer overflow-hidden">
      <img
        className="w-full animate-fadeIn block transform transition-transform duration-300 lg:hover:scale-105"
        src={photograph.thumbnailUrl}
        alt={photograph.id}
        onClick={() => setPhotograph(photograph.id)}
      />
      <div className="w-full absolute bottom-0 right-0 flex items-center justify-end p-2 opacity-100 md:opacity-0 md:group-hover:opacity-100 duration-200 transition-opacity">
        <Tooltip title={t('Download')} arrow>
          <div className="flex items-center justify-center w-8 h-8 bg-lumepic-semi_medium_grey/80 backdrop-blur-[2px] rounded-full hover:bg-lumepic-semi_medium_grey transition-colors duration-200">
            {store.downloadingPhotographIds.has(photograph.id) ? (
              <div className="flex items-center justify-center w-full h-full">
                <Spinner size={16} noStyles />
              </div>
            ) : (
              <FontAwesomeIcon
                onClick={handleDownload}
                icon={faDownload}
                className="cursor-pointer text-base text-lumepic-black hover:scale-110 transition-transform duration-200"
              />
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default observer(GallerySinglePhoto)
