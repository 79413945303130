import { observer } from 'mobx-react'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import StoresContext from '../../providers/storesContext'
import { useTranslation } from 'react-i18next'
import 'moment/locale/es'
import { FEED_ALBUMS } from '../../routing/Paths'
import { isNil } from 'lodash'
import BackButton from 'components/UI/Button/BackButton'
import Tracker from '../../shared/tracking'
import AlbumDetailsStore from './AlbumDetailsStore'
import { useQuery } from 'hooks/useQuery'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import { BurstModeIntervalOption, PhotographUtils } from 'shared/util/photographs.utils'
import Spinner from 'components/UI/Spinner'
import { FeatureFlags } from 'config/constants/featureFlags'
import {
  calculateTotalPhotographsPrice,
  getFeatureFlagValue,
  getFirstNumberOfPicturesForDiscount,
  getQtyDiscountPercentageEarned,
  SetPageTitle,
  updatePackageBarVisibility,
} from 'shared/utility'
import DetailsPackageBar from './components/DetailsPackageBar'
import { TagKeys } from '../../services/RequestInterfaces/Tag/Tag.interface'
import { toast } from 'react-toastify'
import PrivateAlbumSection from './components/PrivateAlbum/PrivateAlbumSection'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/axios'
import useHideChatBot from 'hooks/useHideChatBot'
import AlbumDetailsContent from './components/AlbumDetailsContent'
import { TagPackageType } from '../../shared/models/Cart/Cart'

export type AlbumDetailsParams = {
  id: string
  photographId: string
}

const AlbumDetails = () => {
  useHideChatBot()
  const { t } = useTranslation()
  const history = useHistory()
  const { authStore, cartStore } = useContext(StoresContext)!
  const { id, photographId } = useParams<AlbumDetailsParams>()
  const query = useQuery()
  const page = Number(query.get('page'))
  const tagValue = query.get('tagValue')
  const [albumDetailsStore] = useState(() => new AlbumDetailsStore(id, authStore, tagValue))
  const { album, photographs } = albumDetailsStore
  const initialRender = useRef(true)
  const [showBuyPackageButton, setShowBuyPackageButton] = useState(false)
  const [buyNowPrice, setBuyNowPrice] = useState<number | null>(null)
  const filtersApplied =
    albumDetailsStore.isFilteringByFaceRecognition || albumDetailsStore.tagIds.length > 0

  //FLAGS
  const { featureFlagsStore } = useContext(StoresContext)!

  const handlePackagePurchaseEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.ALBUMS_PACKAGE_PURCHASE,
    false
  )

  const defaultInterval: DropdownOption = { value: '60', label: '60 minutes' }

  const [selectedInterval, setSelectedInterval] = useState<DropdownOption>(defaultInterval)
  const [isBurstMode, setIsBurstMode] = useState<boolean>(false)

  if (!isNil(album.location.spotName)) {
    SetPageTitle(album.location.spotName)
  }

  useEffect(() => {
    albumDetailsStore.fetchAlbumDetails().then(() => {
      Tracker.viewAlbumDetails(album, photographs)
      if (
        !albumDetailsStore.album.isPrivate ||
        (albumDetailsStore.album.isPrivate && albumDetailsStore.isAuthenticated)
      ) {
        if (initialRender.current && !isNil(photographId)) {
          albumDetailsStore.fetchPhotograph(id, photographId)
          initialRender.current = false
        } else {
          albumDetailsStore.fetchPhotographs(true, albumDetailsStore.tagValue.value)
          initialRender.current = false
        }
      }
    })

    albumDetailsStore.fetchAlbumTags()
    if (!isNil(page) && page > 1) {
      albumDetailsStore.setPage(page)
    }
  }, [id, albumDetailsStore])

  const goBack = () => {
    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(FEED_ALBUMS)
    }
  }

  const handleBurstModeChange = (isChecked: boolean) => {
    setIsBurstMode(isChecked)
    if (isChecked) {
      setSelectedInterval(BurstModeIntervalOption)
    } else {
      setSelectedInterval(defaultInterval)
    }
  }

  const downloadFreePhotograph = (photographId: string) => {
    if (!album.isFree) {
      return
    }

    Tracker.downloadFreePhotograph(photographId, album.id)
    return albumDetailsStore.downloadFreePhotograph(photographId)
  }

  const photographBlocksByInterval = PhotographUtils.buildPhotographsBlocksByInterval(
    photographs,
    selectedInterval
  )

  function fetchNextPage() {
    albumDetailsStore.fetchNextPage()
  }

  if (albumDetailsStore.albumNotFound) {
    history.push('/feed/albums')
  }

  const handleBuyPackage = () => {
    if (albumDetailsStore.tagIds.length === 1 || albumDetailsStore.isFilteringByFaceRecognition) {
      const photographs = albumDetailsStore.photographs.map((photograph) => {
        return {
          id: photograph.id,
        }
      })

      let tagId = albumDetailsStore.tagIds[0]
      if (albumDetailsStore.isFilteringByFaceRecognition) {
        const faceTag = albumDetailsStore.photographs
          .map((photograph) => photograph.tags)
          .reduce((acc, tags) => {
            return acc.filter((tag) => tags.some((t) => t.id === tag.id))
          })
          .find((tag) => tag.key === TagKeys.Face)
        if (isNil(faceTag)) {
          toast.error(t('Face tag not found'))
        }
        tagId = faceTag!.id
      }
      Tracker.addPackagePaymentInformation(photographs, album)
      cartStore.createTagPackagePurchase(tagId!, album.currency, [album]).then((purchase) => {
        if (!isNil(purchase)) {
          window.location.href = purchase.paymentUrl!
        }
      })
    }
  }

  const handleAddPackage = (price: number) => {
    if (albumDetailsStore.tagIds.length === 1 || albumDetailsStore.isFilteringByFaceRecognition) {
      let tagId: string
      if (albumDetailsStore.tagIds.length === 1) {
        tagId = albumDetailsStore.tagIds[0]
      }
      const appliesPackagePrice = price === album.defaultPackagePrice

      Tracker.addPackagePaymentInformation(photographs, albumDetailsStore.album)
      if (appliesPackagePrice) {
        cartStore.addAlbumTagPackage(
          albumDetailsStore.album,
          albumDetailsStore.photographs,
          albumDetailsStore.photographCount,
          albumDetailsStore.isFilteringByFaceRecognition
            ? TagPackageType.Face
            : TagPackageType.Text,
          tagId!
        )
      } else {
        for (const photograph of photographs) {
          if (!isNil(album)) {
            cartStore.addPhotograph(photograph, album)
          }
        }
      }
    }
  }

  const memoizedValues = useMemo(() => {
    if (!isNil(photographs) && photographs.length > 0) {
      const totalPhotographsPrice = calculateTotalPhotographsPrice(
        albumDetailsStore.photographs[0].price,
        albumDetailsStore.photographCount
      )
      const totalPhotographsPriceAfterDiscounts = !isNil(album.quantityDiscount)
        ? (1 -
            getQtyDiscountPercentageEarned(
              album.quantityDiscount,
              albumDetailsStore.photographCount
            ) /
              100) *
          totalPhotographsPrice
        : 0
      const minNumberOfPhotosForQtyDiscount = !isNil(album.quantityDiscount)
        ? getFirstNumberOfPicturesForDiscount(album.quantityDiscount)
        : null
      return {
        totalPhotographsPrice,
        totalPhotographsPriceAfterDiscounts,
        minNumberOfPhotosForQtyDiscount,
      }
    }

    return {
      totalPhotographsPrice: 0,
      totalPhotographsPriceAfterDiscounts: 0,
      minNumberOfPhotosForQtyDiscount: null,
    }
  }, [filtersApplied, album.quantityDiscount, photographs, album.defaultPackagePrice])

  const {
    totalPhotographsPrice,
    totalPhotographsPriceAfterDiscounts,
    minNumberOfPhotosForQtyDiscount,
  } = memoizedValues

  useEffect(() => {
    const shouldShowPackageBar = updatePackageBarVisibility(
      filtersApplied,
      albumDetailsStore,
      handlePackagePurchaseEnabled,
      totalPhotographsPrice,
      totalPhotographsPriceAfterDiscounts,
      minNumberOfPhotosForQtyDiscount
    )
    setShowBuyPackageButton(shouldShowPackageBar.showBuyPackageButton)
    setBuyNowPrice(shouldShowPackageBar.buyNowPrice)
  }, [albumDetailsStore.photographs])

  if (!album || cartStore.isLoading) {
    return <Spinner />
  }

  return (
    <div className="mt-3 mb-24 overflow-hidden">
      <div className="container mx-auto">
        <div className="mb-2 ml-2">
          <BackButton onClick={goBack} />
        </div>
        {(!album.isPrivate || (album.isPrivate && albumDetailsStore.isAuthenticated)) && (
          <div className="rounded-md">
            <AlbumDetailsContent
              initialRender={initialRender}
              albumDetailsStore={albumDetailsStore}
              photographId={photographId}
              isBurstMode={isBurstMode}
              handleBurstModeChange={handleBurstModeChange}
              fetchNextPage={fetchNextPage}
              downloadFreePhotograph={downloadFreePhotograph}
              setShowBuyPackageButton={setShowBuyPackageButton}
            />
          </div>
        )}
        {album.isPrivate && !albumDetailsStore.isAuthenticated && (
          <PrivateAlbumSection
            store={albumDetailsStore}
            initialRender={initialRender}
            albumId={id}
            photographId={photographId}
          />
        )}
      </div>
      {showBuyPackageButton && !isNil(buyNowPrice) && (
        <DetailsPackageBar
          handleBuyPackage={() => handleBuyPackage()}
          photographBlocksByInterval={photographBlocksByInterval}
          packagePrice={buyNowPrice}
          album={album}
          isFilteringByFaceRecognition={albumDetailsStore.isFilteringByFaceRecognition}
          handleAddPackage={() => handleAddPackage(buyNowPrice)}
          photographCount={albumDetailsStore.photographCount}
        />
      )}
    </div>
  )
}

export default withErrorHandler(observer(AlbumDetails), axios)
