import React, { useCallback, useContext, useEffect, useState } from 'react'
import { faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import Button from 'components/UI/Button'
import storesContext from 'providers/storesContext'
import { ProgressInfo } from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/UploadImageToAlbum/UploadImageToAlbumStore'
import { isNil } from 'lodash'
import ProfileStore from 'containers/MyAccount/components/UpdateUser/ProfileStore'
import ProfileImageUploaded from 'components/UI/ImageUploaded/ImageUploaded'
import { useFormik } from 'formik'
import { updateProfileValidationSchema } from './components/UpdateProfileValidationSchema'
import UpdateUserInterface from 'services/RequestInterfaces/User/UpdateUser.interface'
import { User } from 'shared/models/User'
import { InputAdornment, MenuItem, Select, TextField } from '@mui/material'
import InputInformation from 'components/UI/InputInformation'
import { Countries } from 'shared/util/countries'
import { Country } from 'shared/models/Country'
import ImageUploader from 'components/UI/ImageUploader/ImageUploader'

type UpadteProfileFormProps = {
  store: ProfileStore
  onSuccess: (data: UpdateUserInterface) => void
  isRequestingPhotographerRole?: boolean
  user: User
}

const UpadteProfileForm = ({
  store,
  onSuccess,
  isRequestingPhotographerRole,
  user,
}: UpadteProfileFormProps) => {
  const { t } = useTranslation()
  const { authStore, locationStore } = useContext(storesContext)!
  const [countryCode, setCountryCode] = useState<string>(locationStore.userLocation.country.code)

  useEffect(() => {
    store.setDefaultProfilePicture(authStore.getLoggedUser())
  }, [])

  const auxSetProgressInfo = useCallback((pginfo: ProgressInfo) => {
    store.changeProgressInfo(pginfo)
  }, [])

  const onSubmitImage = (image: File) => {
    store.changeImageSubmitted(true)
    store.changeImage(image)
    const progressInfo = {
      percentage: 0,
      fileName: image.name,
      requestSent: false,
      error: false,
      size: image.size,
      type: image.type,
    }
    auxSetProgressInfo(progressInfo)
  }

  const userIcon = (
    <span className="w-5 h-6 mt-1 rounded-sm bg-primary">
      <FontAwesomeIcon icon={faUser} className="text-white  pt-1 px-1 pb-0.5 text-sm" />
    </span>
  )
  const phoneIcon = (
    <span className="rounded-sm bg-primary flex items-center justify-center h-6 w-6 my-0.5">
      <FontAwesomeIcon icon={faPhone} className="px-1 text-sm text-white" />
    </span>
  )
  const instagramIcon = (
    <div className="flex gap-1.5 items-center">
      <span className="flex items-center w-5.5 h-6 mb-px bg-primary rounded-sm">
        <FontAwesomeIcon icon={faInstagram} className="px-1 text-white" />
      </span>
      <span>@</span>
    </div>
  )
  const facebookIcon = (
    <div className="flex gap-1.5 items-center">
      <span className="flex items-center w-6 h-6 py-px rounded-sm bg-primary">
        <FontAwesomeIcon icon={faFacebookF} className="text-white px-1.5" />
      </span>
      <span>@</span>
    </div>
  )

  useEffect(() => {
    if (store.imageSubmitted && !isNil(store.image)) {
      store.uploadImage()
    } else if (store.imageSubmitted && isNil(store.image)) {
      store.changeImageSubmitted(false)
    }
  }, [auxSetProgressInfo, store.image])

  let imageDisplayView
  !store.profilePictureUrl
    ? (imageDisplayView = <ImageUploader onUpload={onSubmitImage} isProfilePicture />)
    : (imageDisplayView = <ProfileImageUploaded onUpload={onSubmitImage} store={store} />)

  const userCountry = Countries.find((country) => country.code === user.countryCode)

  const initialValues: UpdateUserInterface = !isRequestingPhotographerRole
    ? {
        alias: user.alias || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phonePrefix: user.phonePrefix ? user.phonePrefix : userCountry?.prefix,
        phoneNumber: user.phoneNumber || '',
        instagramUsername: user.instagramUsername || '',
        facebookUsername: user.facebookUsername || '',
        profilePictureUrl: user.profilePictureUrl || undefined,
        countryCode: user.countryCode || '',
      }
    : {
        alias: '',
        firstName: user.firstName,
        lastName: user.lastName,
        phonePrefix: locationStore.userLocation.country.prefix,
        phoneNumber: '',
        instagramUsername: '',
        facebookUsername: '',
        countryCode: countryCode,
        profilePictureUrl: undefined,
      }

  const formik = useFormik({
    initialValues,
    validationSchema: updateProfileValidationSchema(),
    onSubmit: (values) => {
      values.profilePictureUrl = store.profilePictureUrl || values.profilePictureUrl
      onSuccess(values)
    },
  })

  const countryOptions = Countries.map((country: Country) => {
    const flagClass = `ml-2 mx-2 fi fi-${country.code.toLowerCase()}`
    return (
      <MenuItem key={country.code} value={country.code}>
        <span className={flagClass} />
        {country.name.toUpperCase()}
      </MenuItem>
    )
  })

  const handleChangeCountry = (e: React.ChangeEvent<any>) => {
    setCountryCode(e.target.value)
    formik.setFieldValue('countryCode', e.target.value)
  }

  const phonePrefixDropdown = (
    <Select
      required
      value={formik.values.phonePrefix}
      onChange={(event) => formik.setFieldValue('phonePrefix', event.target.value)}
      variant="standard"
      name="phonePrefix"
      disableUnderline
      sx={{
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '.MuiSelect-select': { border: 'none' },
        '.MuiMenuItem-selected': { backgroundColor: 'transparent' },
      }}
    >
      {Countries.map((country) => (
        <MenuItem key={country.prefix} value={country.prefix}>
          {country.code} ({country.prefix})
        </MenuItem>
      ))}
    </Select>
  )

  return (
    <div className="w-full flex justify-center pb-3 px-2">
      <div className="w-full md:w-3/4 rounded-md bg-white my-2.5 p-3.5 md:pl-2">
        <div className="flex flex-col gap-8 sm:flex-row sm:gap-4">
          <div className="flex flex-col w-full sm:w-1/3">{imageDisplayView}</div>
          <div
            className={`flex flex-col gap-4 w-full sm:w-2/3 ${
              !isRequestingPhotographerRole ? 'gap-3' : ''
            }`}
          >
            <div className="flex flex-col gap-4">
              {!isRequestingPhotographerRole && (
                <div className="flex flex-col gap-4 sm:flex-row sm:gap-2.5">
                  <div className="flex flex-col w-full sm:w-1/2">
                    <TextField
                      required
                      fullWidth
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                      helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                  </div>
                  <div className="w-full sm:w-1/2">
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                      helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-4 sm:flex-row sm:gap-2.5">
                <div className="flex flex-col w-full sm:w-1/2">
                  <TextField
                    required
                    fullWidth
                    id="alias"
                    name="alias"
                    label="Alias"
                    value={formik.values.alias}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.alias && Boolean(formik.errors.alias)}
                    helperText={formik.touched.alias && formik.errors.alias}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{userIcon}</InputAdornment>,
                    }}
                  />
                  <InputInformation
                    informationText={t('This is the name that will be shown to the public')}
                  />
                </div>
                {/* {!isRequestingPhotographerRole && (   TODO: uncomment condition if we can get phone number from stripe   */}
                <div className="w-full sm:w-1/2">
                  <TextField
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder="123456789"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                    InputProps={{
                      startAdornment: (
                        <div className="flex gap-1">
                          <InputAdornment position="start">{phoneIcon}</InputAdornment>
                          <InputAdornment position="start">{phonePrefixDropdown}</InputAdornment>
                        </div>
                      ),
                    }}
                  />
                </div>
                {/* )} */}
              </div>
            </div>
            <div className={'flex flex-col gap-4 md:flex md:flex-row'}>
              <TextField
                fullWidth
                id="instagramUsername"
                name="instagramUsername"
                label="Instagram Username"
                value={formik.values.instagramUsername}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.instagramUsername && Boolean(formik.errors.instagramUsername)}
                helperText={formik.touched.instagramUsername && formik.errors.instagramUsername}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{instagramIcon}</InputAdornment>,
                }}
              />
              <TextField
                fullWidth
                id="facebookUsername"
                name="facebookUsername"
                label="Facebook Username"
                value={formik.values.facebookUsername}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.facebookUsername && Boolean(formik.errors.facebookUsername)}
                helperText={formik.touched.facebookUsername && formik.errors.facebookUsername}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{facebookIcon}</InputAdornment>,
                }}
              />
            </div>
            {isRequestingPhotographerRole && (
              <div className="mb-3">
                <div className="w-24">
                  <TextField
                    required
                    style={{ width: '200px' }}
                    className="pb-1"
                    variant="outlined"
                    name="countryCode"
                    id="countryCode"
                    select
                    defaultValue={locationStore.userLocation.country?.code}
                    label="Country"
                    value={countryCode}
                    onChange={handleChangeCountry}
                    helperText={formik.touched.countryCode && formik.errors.countryCode}
                  >
                    {locationStore.isLoadingUserLocation ? (
                      <MenuItem value={''}>Loading...</MenuItem>
                    ) : (
                      countryOptions
                    )}
                  </TextField>
                </div>
                <InputInformation
                  informationText={t(
                    'Select the country where you have the bank account in which you want to receive the money from your sales. Please note that you will not be able to modify it'
                  )}
                />
              </div>
            )}
            <div className="hidden md:w-full md:flex md:justify-end md:pt-2">
              <div
                className={`hidden md:flex  ${
                  isRequestingPhotographerRole ? 'md:w-1/2' : 'md:w-1/4'
                }`}
              >
                <Button
                  btnType="PrimaryAction"
                  onClick={store.isLoading || authStore.isLoading ? () => {} : formik.handleSubmit}
                  extraStyle={`w-3/4 md:w-full md:self-end ${
                    (store.isLoading || authStore.isLoading) && 'opacity-70'
                  }`}
                  type={'button'}
                  disabled={isRequestingPhotographerRole ? !formik.values.alias : false}
                >
                  {isRequestingPhotographerRole ? t('Request role') : t('Save')}
                </Button>
              </div>
            </div>
            <div className="flex justify-center md:hidden">
              <Button
                btnType="PrimaryAction"
                onClick={store.isLoading || authStore.isLoading ? () => {} : formik.handleSubmit}
                extraStyle={`w-3/4 md:w-full md:self-end ${
                  (store.isLoading || authStore.isLoading) && 'opacity-70'
                }`}
                type={'button'}
                disabled={isRequestingPhotographerRole ? !formik.values.alias : false}
              >
                {isRequestingPhotographerRole ? t('Request role') : t('Save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(UpadteProfileForm)
