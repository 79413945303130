import local from './local'
import staging from './staging'
import production from './production'
import test from './test'
import DiscountPercentage from '../shared/models/QuantityDiscount'
import { isNil } from 'lodash'

const REACT_APP_ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
  TEST: 'test',
}

let constants = local

switch (process.env.REACT_APP_ENVIRONMENT) {
  case REACT_APP_ENVIRONMENTS.DEVELOPMENT:
    constants = local
    break
  case REACT_APP_ENVIRONMENTS.STAGING:
    constants = staging
    break
  case REACT_APP_ENVIRONMENTS.PRODUCTION:
    constants = production
    break
  case REACT_APP_ENVIRONMENTS.TEST:
    constants = test
    break
}

export const AssetNames = {
  LANDING_MAIN: 'landing-main.jpg',
  LANDING_FOR_PHOTOGRAPHERS: 'landing-for-photographers.jpg',
  LANDING_FOR_ATHLETES: 'landing-for-athletes.jpg',
  LANDING_SECONDARY: 'landing-secondary.jpg',
  PAYMENT_SUCCESS_BANNER: 'payment-success-banner.jpg',
  PAYMENT_FAILURE_BANNER: 'payment-failure-banner.png',
  NO_IMAGE_FOUND: 'no-image-found.png',
  LANDING_BENEFITS_ATHLETE: 'Landing-BenefitsAthlete.svg',
  LANDING_BENEFITS_PHOTOGRAPHER: 'Landing-BenefitsPhotographer.svg',
  LANDING_BENEFITS_SECURE_PAYMENT: 'Landing-BenefitsMercadoPago.svg',
  LANDING_ATHLETICS_DESKTOP: 'Landing-AtletismosDesktop.png',
  LANDING_ATHLETICS_MOBILE: 'Landing-AtletismosMobile.png',
  LANDING_KITE_DESKTOP: 'Landing-KiteDesktop.png',
  LANDING_KITE_MOBILE: 'Landing-KiteMobile.png',
  LANDING_MOTOCROSS_DESKTOP: 'Landing-MotocrossDesktop.png',
  LANDING_MOTOCROSS_MOBILE: 'Landing-MotocrossMobile.png',
  LANDING_RUGBY_DESKTOP: 'Landing-RugbyDesktop.png',
  LANDING_RUGBY_MOBILE: 'Landing-RugbyMobile.png',
  LANDING_SURF_DESKTOP: 'Landing-SurfDesktop.png',
  LANDING_SURF_MOBILE: 'Landing-SurfMobile.png',
  LANDING_HERO_DESKTOP: 'Landing-HeroSectionDesktop.png',
  LANDING_HERO_MOBILE: 'Landing-HeroSectionMobile.png',
  LANDING_C2A_DESKTOP: 'Landing-CallToActionDesktop.svg',
  LANDING_C2A_MOBILE: 'Landing-CallToActionMobile.svg',
  LANDING_METRICS: 'Landing-MetricsBg.png',
  LANDING_PROGRESSBAR_DESKTOP: 'Landing-ProgressBarDesktop.svg',
  LANDING_PROGRESSBAR_MOBILE: 'Landing-ProgressBarMobile.svg',
  PHOTOGRAPHERS_LANDING_HERO_DESKTOP: 'HeroDesktop.png',
  PHOTOGRAPHERS_LANDING_HERO_MOBILE: 'HeroMobile.png',
  PHOTOGRAPHERS_LANDING_PHOTOGRAPHER_GLOW: 'PhotographerGlow.png',
  PHOTOGRAPHERS_LANDING_HOW_IT_WORKS_1: 'PhotographersHowItWorks1.png',
  PHOTOGRAPHERS_LANDING_HOW_IT_WORKS_2: 'PhotographersHowItWorks2.png',
  PHOTOGRAPHERS_LANDING_HOW_IT_WORKS_3: 'PhotographersHowItWorks3.png',
  PHOTOGRAPHERS_LANDING_FACTS_1: 'PhotographerFacts1.png',
  PHOTOGRAPHERS_LANDING_FACTS_2: 'PhotographerFacts2.png',
  PHOTOGRAPHERS_LANDING_FACTS_3: 'PhotographerFacts3.png',
  PHOTOGRAPHERS_LANDING_REVIEWS_1: 'PhotographerReviews1.png',
  PHOTOGRAPHERS_LANDING_REVIEWS_2: 'PhotographerReviews2.png',
  PHOTOGRAPHERS_LANDING_REVIEWS_3: 'PhotographerReviews3.png',
  PHOTOGRAPHERS_LANDING_REVIEWS_4: 'PhotographerReviews4.png',
}

export enum ConfigKeys {
  API_URL = 'API_URL',
  WEB_URL = 'WEB_URL',
  DATE_LOCALE = 'DATE_LOCALE',
  GOOGLE_MAPS_API_KEY = 'GOOGLE_MAPS_API_KEY',
  GOOGLE_CLIENT_ID = 'GOOGLE_CLIENT_ID',
  WHATSAPP_CONTACT_US_NUMBER = 'WHATSAPP_CONTACT_US_NUMBER',
  MERCADOPAGO_CLIENT_ID = 'MERCADOPAGO_CLIENT_ID',
  MERCADOPAGO_RESPONSE_TYPE = 'MERCADOPAGO_RESPONSE_TYPE',
  MERCADOPAGO_PLATFORM_ID = 'MERCADOPAGO_PLATFORM_ID',
  MERCADOPAGO_REDIRECT_URI = 'MERCADOPAGO_REDIRECT_URI',
  USER_CARTS_DATABASE_URL = 'USER_CARTS_DATABASE_URL',
  LAUNCHDARKLY_CLIENT_SIDE_ID = 'LAUNCHDARKLY_CLIENT_SIDE_ID',
  PRINT_PHOTOGRAPH_REQUEST_FORM_URL = 'PRINT_PHOTOGRAPH_REQUEST_FORM_URL',
  USER_EVENT_PHOTOGRAPHS__REQUEST_FORM = 'USER_EVENT_PHOTOGRAPHS__REQUEST_FORM',
  EVENT_REQUEST_FORM = 'EVENT_REQUEST_FORM',
  STRIPE_PAYMENT_URL = 'STRIPE_PAYMENT_URL',
  PERMANENT_USER_UPLOADS_BUCKET = 'PERMANENT_USER_UPLOADS_BUCKET',
  INDEPENDENT_ALBUM_PHOTOGRAPHS_LIMIT = 'INDEPENDENT_ALBUM_PHOTOGRAPHS_LIMIT',
  EVENT_ALBUM_PHOTOGRAPHS_LIMIT = 'INDEPENDENT_ALBUM_PHOTOGRAPHS_LIMIT',
  IP_API_URL = 'IP_API_URL',
  SCROLL_THRESHOLD = 'SCROLL_THRESHOLD',
}

export default class ConfigService {
  public static getValue(key: ConfigKeys): string {
    return constants[key]
  }

  public static buildMercadoPagoCredentials(userId: string) {
    const mercadoPagoClientId = this.getValue(ConfigKeys.MERCADOPAGO_CLIENT_ID)
    const mercadoPagoResponseType = this.getValue(ConfigKeys.MERCADOPAGO_RESPONSE_TYPE)
    const mercadoPagoPlatformId = this.getValue(ConfigKeys.MERCADOPAGO_PLATFORM_ID)
    const mercadoPagoRedirectUri = this.getValue(ConfigKeys.MERCADOPAGO_REDIRECT_URI)
    return `https://auth.mercadopago.com.uy/authorization?client_id=${mercadoPagoClientId}&response_type=${mercadoPagoResponseType}&platform_id=${mercadoPagoPlatformId}&state=userId=${userId}&redirect_uri=${mercadoPagoRedirectUri}`
  }

  public static buildAlbumUrl(albumId: string) {
    return `${constants.WEB_URL}/feed/albums/${albumId}`
  }

  public static buildPhotographUrl(albumId: string, photographId: string) {
    return `${constants.WEB_URL}/feed/albums/${albumId}/${photographId}`
  }

  public static buildFreePhotographUrl(albumId: string, photographId?: string) {
    return `${constants.WEB_URL}/feed/albums/${albumId}/${photographId}`
  }

  public static buildPhotographerProfileUrl(photographerId: string) {
    return `${constants.WEB_URL}/${photographerId}`
  }

  public static getAsset(assetName: string, subfolder?: string) {
    //For local dev, retrieve images from staging
    const reactEnvironment = process.env.REACT_APP_ENVIRONMENT || 'staging'
    if (isNil(subfolder)) {
      return `https://spotted-web-assets.s3.us-east-1.amazonaws.com/${reactEnvironment}/${assetName}`
    } else {
      return `https://spotted-web-assets.s3.us-east-1.amazonaws.com/${reactEnvironment}/${subfolder}/${assetName}`
    }
  }
  public static getBanner(name: string) {
    //For local dev, retrieve images from staging
    const reactEnvironment = process.env.REACT_APP_ENVIRONMENT || 'staging'
    return `https://spotted-web-assets.s3.us-east-1.amazonaws.com/${reactEnvironment}/banners/${name}`
  }
  public static getRecognitionPicture(userId: string) {
    const reactEnvironment = process.env.REACT_APP_ENVIRONMENT || 'local'
    return `${constants.PERMANENT_USER_UPLOADS_BUCKET}/${reactEnvironment}/${userId}/selfie`
  }
  static buildReferralUrl(alias: string): string {
    return `${constants.WEB_URL}/auth?isRegister=true&referredBy=${alias}`
  }
}

export const DefaultDiscountRanges: DiscountPercentage[] = [
  {
    numberOfPictures: 2,
    discountPercentage: 20,
  },
  {
    numberOfPictures: 3,
    discountPercentage: 30,
  },
  {
    numberOfPictures: 4,
    discountPercentage: 40,
  },
  {
    numberOfPictures: 5,
    discountPercentage: 50,
  },
]
