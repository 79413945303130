import { makeAutoObservable, runInAction } from 'mobx'
import { ReferredPhotographerInterface } from './interfaces/ReferredPhotographer.interface'
import axios from '../../services/axios'
import * as paths from '../../services/Paths'
import { parseAxiosErrorResponse } from '../../shared/utility'
import AuthStore from '../../stores/AuthStore'

export default class MyRewardsStore {
  referredPhotographers: ReferredPhotographerInterface[] = []
  isLoading = false
  error: any = null
  currentPage = 1
  totalPages = 1
  totalItems = 0
  itemsPerPage = 2
  private readonly authStore: AuthStore

  constructor(authStore: AuthStore) {
    this.authStore = authStore
    makeAutoObservable(this)
  }

  setPage(page: number) {
    if (page < 1) return
    if (page > this.totalPages) return

    this.currentPage = page
    this.fetchReferredUsers(page, this.itemsPerPage)
  }

  async fetchReferredUsers(page = 1, pageSize = 2) {
    runInAction(() => {
      this.isLoading = true
      this.error = null
    })

    try {
      const token = this.authStore.getToken()
      const userId = this.authStore.getLoggedUser().id

      const { data: response } = await axios.get(paths.userReferredById(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          pageSize,
        },
      })

      runInAction(() => {
        if (response?.data) {
          this.referredPhotographers = response.data.map((photographer: any) => ({
            id: photographer.id,
            name: photographer.name || `${photographer.firstName} ${photographer.lastName}`,
            alias: photographer.alias,
            lastSale: photographer.lastSale || new Date().toISOString(),
            earnings: photographer.earnings || 0,
            validFrom: photographer.photographerRegisteredAt || new Date().toISOString(),
          }))

          if (response.meta) {
            this.totalPages = response.meta.pageCount
            this.totalItems = response.meta.itemCount
            this.currentPage = response.meta.page
            this.itemsPerPage = response.meta.take
          }
        }
      })
    } catch (error) {
      const errorMessage = parseAxiosErrorResponse(error)
      runInAction(() => {
        this.error = errorMessage
        this.referredPhotographers = []
      })
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  hasNextPage(): boolean {
    return this.currentPage < this.totalPages
  }

  hasPreviousPage(): boolean {
    return this.currentPage > 1
  }

  reset() {
    this.referredPhotographers = []
    this.isLoading = false
    this.error = null
    this.currentPage = 1
    this.totalPages = 1
    this.totalItems = 0
    this.itemsPerPage = 2
  }
}
