import React, { useEffect, useRef, useContext, useState } from 'react'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import SearchBar from '../../components/UI/SearchBar'
import AlbumCards from '../../components/Album/AlbumCards/AlbumCards'
import { observer } from 'mobx-react'
import StoresContext from '../../providers/storesContext'
import axios from '../../services/axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import Tracker from '../../shared/tracking'
import SearchBarStore from 'components/UI/SearchBar/SearchBarStore'
import NoResultsFound from 'components/UI/NoResultsFound/NoResultsFound'
import { toast } from 'react-toastify'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import LoginRegisterStore from 'containers/Auth/LoginRegisterStore'
import { useTranslation } from 'react-i18next'
import Spinner from '../../components/UI/Spinner'
import FeedStore from '../../stores/FeedStore'
import { AlbumSearchUrlQueryParams, Filters } from '../../components/UI/SearchBar/SearchBar'
import { isNil } from 'lodash'
import { useQuery } from '../../hooks/useQuery'
import moment from 'moment'
import { getLocalDate } from 'shared/util/dates'
import SkeletonLoaderFeed from 'components/UI/Loaders/Skeleton/SkeletonLoaderFeed'
import { SetPageTitle } from '../../shared/utility'
import { ConfigKeys } from 'config'
import ConfigService from 'config'

const FeedFilters = [
  Filters.Country,
  Filters.Location,
  Filters.Activity,
  Filters.Event,
  Filters.DateRange,
]

const Feed = () => {
  const { authStore, cartStore, locationStore, activityStore, eventStore } =
    useContext(StoresContext)!

  const parseDate = (dateString) => {
    const parsedDate = moment(dateString, 'DD-MM-YYYY', true)
    return parsedDate.isValid() ? parsedDate.toDate() : null
  }

  SetPageTitle('Lumepic | Albums')

  const query = useQuery()
  const countryCode =
    query.get(AlbumSearchUrlQueryParams.CountryCode) ||
    locationStore.userLocation.country.code ||
    undefined
  const locationId = query.get(AlbumSearchUrlQueryParams.LocationId) || undefined
  const activityId = query.get(AlbumSearchUrlQueryParams.ActivityId) || undefined
  const eventId = query.get(AlbumSearchUrlQueryParams.EventId) || undefined

  const dateFrom = parseDate(query.get(AlbumSearchUrlQueryParams.DateFrom))
  const dateTo = parseDate(query.get(AlbumSearchUrlQueryParams.DateTo))

  const apiDateFrom = getLocalDate(dateFrom)
  const apiDateTo = getLocalDate(dateTo)

  if (!isNil(apiDateTo)) {
    apiDateTo.setUTCHours(23, 59, 59, 999)
  }

  const dateRange: [Date | null, Date | null] = [apiDateFrom, apiDateTo]

  const [searchBarStore] = useState(
    () =>
      new SearchBarStore(eventStore, activityStore, locationStore, {
        countryCode,
        locationId,
        activityId,
        eventId,
        dateRange,
      })
  )
  const [feedStore] = useState(() => new FeedStore(authStore, searchBarStore))

  const initialRender = useRef(true)
  const { t } = useTranslation()

  useEffect(() => {
    if (initialRender.current && feedStore.albums.length === 0) {
      feedStore.fetchAlbums()
      Tracker.pageView('/feed/albums', 'Feed Albums')
      initialRender.current = false
    }
  }, [])

  const fetchNextPage = () => {
    feedStore.fetchNextPage()
  }

  const handleSearch = () => {
    feedStore.fetchAlbums(true)
  }

  const [loginRegisterStore] = useState(
    () => new LoginRegisterStore(authStore, cartStore, locationStore)
  )

  const googleLoginSuccess = (credentialResponse: CredentialResponse) => {
    return loginRegisterStore.googleAuth(credentialResponse)
  }
  const googleLoginError = () => {
    toast.error(t('Error on login'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  const loadingSpinner = <Spinner divStyles="flex justify-center my-5 items-center" size={40} />

  return (
    <div className="bg-bg_details">
      <div className="mx-4">
        <div className="w-full">
          <div className="my-4 bg-white px-3 rounded-md shadow-md pb-0.5">
            <SearchBar
              searchBarStore={searchBarStore}
              onSearch={handleSearch}
              filters={FeedFilters}
            />
          </div>
          <div className="row justify-center">
            <InfiniteScroll
              dataLength={feedStore.albums.length}
              next={fetchNextPage}
              hasMore={feedStore.hasMorePages()}
              loader={<></>}
              scrollThreshold={ConfigService.getValue(ConfigKeys.SCROLL_THRESHOLD)}
              className="overflow-hidden"
            >
              {feedStore.albums.length > 0 && <AlbumCards albums={feedStore.albums} />}
            </InfiniteScroll>
            {feedStore.isLoading && feedStore.albums.length === 0 && <SkeletonLoaderFeed />}
            {feedStore.isLoading && feedStore.albums.length > 0 && loadingSpinner}
          </div>
        </div>
      </div>
      {/*TODO: Is this necessary? We're redirecting to event details page*/}
      {!isNil(searchBarStore.event) && feedStore.albums.length == 0 && !feedStore.isLoading && (
        <NoResultsFound emptyEvent={true} />
      )}
      {isNil(searchBarStore.event) && feedStore.albums.length == 0 && !feedStore.isLoading && (
        <NoResultsFound />
      )}

      {!authStore.isAuthenticated() && (
        <div className="hidden">
          <GoogleLogin
            size="large"
            width="150px"
            useOneTap
            onSuccess={googleLoginSuccess}
            onError={googleLoginError}
          />
        </div>
      )}
    </div>
  )
}

export default withErrorHandler(observer(Feed), axios)
