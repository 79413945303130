import React, { useState } from 'react'
import Modal from '../Modal'
import { ModalButtonsDisplay, ModalSize } from '../Modal/Modal'
import ShareModalContent from './components/ShareModalContent'

type ShareModalProps = {
  closeModal: () => void
  isModalOpened: boolean
  shareUrl: string
  shareTitle: string
  shareDescription?: string
}

const ShareModal = ({
  closeModal,
  isModalOpened,
  shareUrl,
  shareTitle,
  shareDescription,
}: ShareModalProps) => {
  const [isCopied, setIsCopied] = useState(false)
  const handleCloseModal = () => {
    closeModal()
    setIsCopied(false)
  }

  return (
    <Modal
      onCancel={handleCloseModal}
      opened={isModalOpened}
      modalSize={ModalSize.SMALL}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
    >
      <ShareModalContent
        isCopied={isCopied}
        setIsCopied={setIsCopied}
        shareUrl={shareUrl}
        shareTitle={shareTitle}
        shareDescription={shareDescription}
      />
    </Modal>
  )
}

export default ShareModal
