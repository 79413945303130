import React from 'react'
import ConfigService, { ConfigKeys } from '../../../config'

const baseUrl = ConfigService.getValue(ConfigKeys.WEB_URL)
const data = [
  {
    id: '1',
    section: 0,
    title: 'Who is Lumepic?',
    content:
      'Lumepic is all of us who make use of it. Behind Lumepic, there’s a team passionate about sports and photography, seeking to enhance the photographer-athlete relationship for the benefit of everyone!',
  },
  {
    id: '2',
    section: 0,
    title: 'What is Lumepic?',
    content:
      'It’s a community created by and for athletes and photographers. It’s the meeting point where we athletes can find our best photos and immortalize incredible moments, and photographers can turn our hobby into a profession.',
  },
  {
    id: '3',
    section: 0,
    title: 'Who can use Lumepic?',
    content:
      'Everyone can use Lumepic! We invite all sports photographers to give it a try, and all athletes to connect. Additionally, sports event organizers can rely on Lumepic to cover photography and make it easy for all participants to find themselves.',
  },
  {
    id: '4',
    section: 0,
    title: 'How does Lumepic work?',
    content: (
      <span>
        It’s simple: <br />
        1- Photographers take the photos. <br />
        2- They upload the high-quality album to the platform, which automatically publishes them in
        low quality with a watermark.
        <br />
        3- Athletes simply search for their photos and have the option to purchase and download them
        in high quality without a watermark.
      </span>
    ),
  },
  {
    id: '5',
    section: 1,
    title: `Can anyone register as a photographer?`,
    content: `Yes! We invite all photographers to register and start working with Lumepic. We have no requirements for registration other than having an email address.`,
  },
  {
    id: '6',
    section: 1,
    title: `What type of photography can be sold on Lumepic?`,
    content: `Currently, we work with all types of Sports Photography.`,
  },
  {
    id: '7',
    section: 1,
    title: `How do I register as a photographer?`,
    content: (
      <span>
        You need to go to{' '}
        <a href={`${baseUrl}/auth?isRegister=true`} style={{ color: '#D4C581' }}>
          this link
        </a>{' '}
        and complete your details. Then from “My Albums”, you can complete the registration process
        as a photographer.
      </span>
    ),
  },
  {
    id: '8',
    section: 1,
    title: `How do I sell my photos on Lumepic?`,
    content: `Once you've registered as a photographer and completed the connection with the payment processor, Stripe, you can go to “My Albums” and upload your first album. It's important to share the album link on social media and with individuals associated with the sports event to achieve the widest reach. Sales will happen automatically, and you'll receive the money in your bank account linked to Stripe.`,
  },
  {
    id: '9',
    section: 1,
    title: `When can I access the money from my sales?`,
    content: `The payment processor, Stripe, will make weekly transfers to your linked bank account.`,
  },
  {
    id: '10',
    section: 1,
    title: `How will my photos be organized?`,
    content: `Your photos will be organized by date and time. Remember to adjust your camera’s time settings to facilitate searching. Keep in mind that the easier it is to find photos, the more likely athletes will make purchases, leading to more income.`,
  },
  {
    id: '11',
    section: 1,
    title: `How will athletes search for photos?`,
    content: `Athletes can search for albums by event, sport, date and photographer. Once inside the album, they can find themselves by schedule, competitor number, and other numbers or words automatically recognized by our system in the photos.`,
  },
  {
    id: '12',
    section: 1,
    title: `Does uploading my photos mean that everyone can see them without paying for them?`,
    content: `No! Well, yes and no, actually! Lumepic is a community, so many people can view your photos (in low quality with a watermark). For this reason, it's likely that they will reach the athletes appearing in them, making it possible for those interested to buy them. In the end, using Lumepic can significantly increase your photo sales income.`,
  },
  {
    id: '13',
    section: 1,
    title: `Do my photos need to meet quality standards? What quality should I upload the photos in?`,
    content: `The formats accepted by the platform today are JPG, JPEG, or PNG. There's no minimum set weight or quality, but we recommend photographers to upload images of at least 1 MB in size and not exceeding 20 MB. Lumepic encourages proper platform usage and reserves the right to remove any content that doesn't meet these requirements.`,
  },
  {
    id: '14',
    section: 1,
    title: `All sounds good, but... What does Lumepic charge for its service?`,
    content: (
      <span>
        <p>
          Lumepic has a large team to provide its service. Therefore, it charges photographers a 20%
          commission on photo sales to ensure operational sustainability and growth. The larger the
          community, the more we all benefit!{' '}
        </p>
        <br />
        <p>
          For certain events or activities, the commission percentage may be modified. Check the
          conditions for each event.
        </p>
        <br />
        <p>
          Additionally, we have the Fidelity Code, which allows photographers to decrease the
          commissions charged by Lumepic. You can learn more about it in the question:{' '}
          <i> How does the Fidelity Code work?</i>.
        </p>
      </span>
    ),
  },
  {
    id: '15',
    section: 1,
    title: `How do discounts work?`,
    content: `Photographers can choose to offer quantity-based discounts when uploading the album or later from "My Albums." The system automatically applies quantity discounts – we want to sell as many photos as possible (we all know photos sitting on a hard drive are wasted!). So, the more photos a user buys, the lower the cost per photo, increasing the overall sale but decreasing the per-unit cost.`,
  },
  {
    id: '16',
    section: 1,
    title: `How does the Fidelity Code work?`,
    content: (
      <span>
        <ul>
          <li className="list-disc ml-6">
            What is it?
            <ul>
              <li className="list-[circle] ml-6">
                It&apos;s a promotion designed to incentivize photographers to promote the use of
                Lumepic for selling their photos, while paying a lower Lumepic commission.
              </li>
            </ul>
          </li>
          <li className="list-disc ml-6">
            What&apos;s its purpose?
            <ul>
              <li className="list-[circle] ml-6">
                To encourage photographers to share it with their clients, who can then use it when
                making a purchase in their albums.
              </li>
            </ul>
          </li>
          <li className="list-disc ml-6">
            What&apos;s the outcome of using it?
            <ul>
              <li className="list-[circle] ml-6">
                When an athlete makes a purchase in a photographer&apos;s album using their
                promotional code, two things will happen:
                <ol>
                  <li className="list-[square] ml-6">
                    i. Lumepic WON&apos;T CHARGE A COMMISSION for all purchases the athlete makes in
                    their albums for ONE YEAR (though the payment processor&apos;s fee will still
                    apply).
                  </li>
                  <li className="list-[square] ml-6">
                    ii. The athlete will receive a discount on the total value of that purchase
                    (valid only for the athlete&apos;s first purchase).
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li className="list-disc ml-6">
            How to get started?
            <ul>
              <li className="list-[circle] ml-6">
                Each photographer must generate their loyalty code in “My Account” by clicking
                &quot;Create Personal Discount Code&quot; and then selecting:
                <ol>
                  <li className="list-[square] ml-6">
                    i. The code&apos;s name: the text athletes need to enter when making a purchase.
                  </li>
                  <li className="list-[square] ml-6">
                    ii. The % discount to apply. This % will be deducted from the total purchase
                    value, even if other discounts have already been applied to that purchase.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
        </ul>
      </span>
    ),
  },
  {
    id: '17',
    section: 2,
    title: `How does Lumepic work?`,
    content: `Lumepic is a platform where photographers upload registered photos from a particular event so that photographed athletes can access, view, and easily purchase them for high-quality download without watermarks. By purchasing through Lumepic, you support the work of the photographers!`,
  },
  {
    id: '18',
    section: 2,
    title: `How can I buy my photos?`,
    content: `You can start by searching for an album or event, filtering by date or location, and even by the event's name. Once you find the event, you can locate your photos by time slot or competitor number. When you find your photos, you can easily add them to your cart and proceed to pay with any credit or debit card.`,
  },
  {
    id: '19',
    section: 2,
    title: `I made a purchase but I'm not sure how to access my photos...`,
    content: `You can access your photos by logging in to Lumepic with your username and navigating to “My Purchases”.`,
  },
  {
    id: '20',
    section: 2,
    title: `How does the discount system work?`,
    content: `Photographers can choose to offer quantity-based discounts. Discounts apply to each album independently. Upon entering the album, you'll be able to see the discount system applicable for that specific album. The system automatically applies quantity discounts, and the more photos you purchase, the lower the cost per photo.`,
  },
  {
    id: '21',
    section: 2,
    title: `I've already bought my photos, how can I download them?`,
    content: `You can access your photos by logging into Lumepic with your username and password, then navigating to the “Purchases” section from the menu. There, you'll see the list of your purchases and the photos you bought. Pressing the “download” button will start the download. It might take from seconds to minutes as the photos are of very high quality.`,
  },
  {
    id: '22',
    section: 2,
    title: `My card payments are being declined, what could be happening?`,
    content: `Perhaps you don&apos;t have sufficient funds or your bank is rejecting the payment for some reason? We recommend checking with your bank. You can also try some other card. If the problem persists, you can write to us at info@lumepic.com`,
  },
  // TODO: add section "ORGANIZADORES DE EVENTOS"
]

export default data
