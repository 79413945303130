import { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import storesContext from '../../../providers/storesContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export interface VerifyUserRouteParamsInterface {
  token: string
}

const VerifyUser = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authStore } = useContext(storesContext)!

  const { token } = useParams<VerifyUserRouteParamsInterface>()

  useEffect(() => {
    authStore.verifyUser(token).then((result) => {
      if (result) {
        authStore.getUser().then(() => {
          history.push('/my-account')
        })
      } else {
        toast.error(t('The user could not be verified'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        history.push('/auth')
      }
    })
  }, [])

  return null
}

export default observer(VerifyUser)
