import { makeAutoObservable, runInAction } from 'mobx'
import InputStore from '../../shared/store/InputStore'
import { string } from 'yup'
import { toast } from 'react-toastify'
import AuthStore from '../../stores/AuthStore'
import { Role, User } from '../../shared/models/User'
import { RoleType } from '../../services/RequestInterfaces/Role/Role.interface'
import { CredentialResponse } from '@react-oauth/google'
import CartStore from 'stores/CartStore'
import LocationStore from 'stores/LocationStore'
import { isNil } from 'lodash'

class LoginRegisterStore {
  public clientEmail: InputStore<string>
  public adminEmail: InputStore<string>
  public loginPassword: InputStore<string>
  public password: InputStore<string>
  public firstName: InputStore<string>
  public lastName: InputStore<string>
  public referredBy: InputStore<string>
  public loginOnBehalf = false
  public serverError: any
  constructor(
    private readonly authStore: AuthStore,
    private readonly cartStore: CartStore,
    private readonly locationStore: LocationStore,
    referredByParam?: string | null
  ) {
    this.reset(referredByParam)
    makeAutoObservable(this)
  }

  emailRegExp = RegExp(/^[^@ \t\r\n]+@[^@ \t\r\n]+\.[a-zA-Z]{2,3}$/)

  reset(referredByParam?: string | null) {
    this.clientEmail = new InputStore(
      string()
        .matches(this.emailRegExp, 'Ingrese un formato de email válido')
        .required('El email es requerido')
    )
    this.adminEmail = new InputStore(
      string().matches(this.emailRegExp, 'Ingrese un formato de email válido').optional()
    )
    this.loginPassword = new InputStore(string().required('La contraseña es requerida'))
    this.password = new InputStore(
      string()
        .required('La contraseña es requerida')
        .min(6, 'La contraseña debe tener al menos 6 caracteres')
    )
    this.firstName = new InputStore(string().required('El nombre es requerido'))
    this.lastName = new InputStore(string().required('El apellido es requerido'))
    this.referredBy = new InputStore(string().optional())
    this.referredBy.setValue(referredByParam || '')
    this.referredBy.setDisabled(!isNil(referredByParam))
  }

  // API Authentications.
  async login() {
    if (await this.validateLogin()) {
      try {
        if (this.loginOnBehalf) {
          await this.authStore.loginOnBehalf(
            this.adminEmail.value,
            this.loginPassword.value,
            this.clientEmail.value
          )
        } else {
          await this.authStore.login(this.clientEmail.value, this.loginPassword.value)
        }
        await this.cartStore.fetchCart()
        return true
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }
          toast.warning(displayedError, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
      }
    }

    return false
  }

  async register() {
    if (await this.validateRegister()) {
      try {
        const newUser = new User()
        newUser.email = this.clientEmail.value
        newUser.password = this.password.value
        newUser.firstName = this.firstName.value
        newUser.lastName = this.lastName.value
        newUser.countryCode = this.locationStore.userLocation.country.code
        newUser.roles = []

        const athleteRole = new Role()
        athleteRole.type = RoleType.ATHLETE
        newUser.roles.push(athleteRole)

        if (this.referredBy.value) {
          newUser.referredBy = this.referredBy.value
        }

        await this.authStore.register(newUser)
        return true
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }
        })
      }
    }

    return false
  }

  // Google Authentications
  async googleAuth(credentialResponse: CredentialResponse): Promise<void> {
    const countryCode = this.locationStore.userLocation.country.code
    await this.authStore.googleAuth(credentialResponse, countryCode)
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'email':
          this.clientEmail.setError(true, error)
          displayedError = true
          break

        case 'password':
          this.password.setError(true, error)
          displayedError = true
          break

        case 'fistName':
          this.firstName.setError(true, error)
          displayedError = true
          break

        case 'lastName':
          this.lastName.setError(true, error)
          displayedError = true
          break

        case 'referredBy':
          this.referredBy.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }

  // Field Validators & accessors
  get isLoading() {
    return this.authStore.isLoading
  }

  changeClientEmail(val: string) {
    this.clientEmail.setValue(val.trim())
  }

  changeAdminEmail(val: string) {
    this.adminEmail.setValue(val.trim())
  }

  setLoginOnBehalf(val: boolean) {
    this.loginOnBehalf = val
  }

  changeLoginPassword(val: string) {
    this.loginPassword.setValue(val)
  }

  changePassword(val: string) {
    this.password.setValue(val)
  }

  changeFirstName(val: string) {
    this.firstName.setValue(val)
  }

  changeLastName(val: string) {
    this.lastName.setValue(val)
  }

  changeReferredBy(val: string) {
    if (!this.referredBy.isDisabled) {
      this.referredBy.setValue(val.trim())
    }
  }

  clearErrors() {
    this.clientEmail.clearError()
    this.loginPassword.clearError()
    this.password.clearError()
    this.firstName.clearError()
    this.lastName.clearError()
  }

  async validateLogin() {
    this.clearErrors()
    let isValid = true

    if (!(await this.clientEmail.validate())) {
      isValid = false
    }

    if (!(await this.loginPassword.validate())) {
      isValid = false
    }
    return isValid
  }

  async validateRegister() {
    this.clearErrors()
    let isValid = true

    if (!(await this.firstName.validate())) {
      isValid = false
    }

    if (!(await this.lastName.validate())) {
      isValid = false
    }

    if (!(await this.clientEmail.validate())) {
      isValid = false
    }

    if (!(await this.password.validate())) {
      isValid = false
    }

    return isValid
  }
}

export default LoginRegisterStore
