import { Photograph } from 'shared/models/Photograph'
import { Currency } from 'shared/util/Currency'
import { CartLine, CartLineType } from './CartLine'
import Album from '../../Album'
import { Event } from '../../Event'
import { isNil } from 'lodash'
import { formatDateShort } from 'shared/utility'

/**
 * Class representing a cart line for a photograph.
 */
export class PhotographCartLine extends CartLine {
  /** The photograph associated with this cart line. */
  photograph: Photograph

  /** The album associated with the photograph. */
  album: Album

  /** The event associated with the photograph. */
  event?: Event

  /**
   * Creates an instance of PhotographCartLine.
   * @param photograph - The photograph associated with this cart line.
   * @param subtotalPrice - The subtotal price of the photograph.
   * @param totalPrice - The total price of the photograph.
   * @param discountTotal - The total discount applied to the photograph.
   * @param currency - The currency of the photograph.
   * @param album - The album associated with the photograph.
   * @param event - The event associated with the photograph.
   */
  constructor(
    photograph: Photograph,
    subtotalPrice: number,
    totalPrice: number,
    discountTotal: number,
    currency: Currency,
    album: Album,
    event?: Event
  ) {
    super(CartLineType.Photograph, subtotalPrice, totalPrice, discountTotal, currency)
    this.photograph = photograph
    this.album = album
    this.event = event
  }

  getTitle(): string {
    return !isNil(this.event) && !isNil(this.event.id) //We validate both, event and event.id because just with event it brings an object with undefined values
      ? this.event.name
      : `Album from ${formatDateShort(this.album.takenDate)}`
  }

  getDetails(): string {
    return `${this.album.location.spotName} | ${this.album.activity.name}`
  }

  getKey(): string {
    return `photograph-${this.photograph.id}`
  }

  //Purpose for this Key: grouping under same the cart view accordion
  getGroupKey(): string {
    return !isNil(this.event) && !isNil(this.event.id)
      ? `event-${this.event.id}`
      : `album-${this.album.id}`
  }
}
