import { action, makeAutoObservable, runInAction } from 'mobx'
import Album from 'shared/models/Album'
import { Photograph } from 'shared/models/Photograph'
import AuthStore from 'stores/AuthStore'
import { EventService } from 'services/EventService'
import FeedService, { FeedOrderFields, FeedOrderSorts } from 'services/FeedService'
import { Event } from 'shared/models/Event'
import InputStore from 'shared/store/InputStore'
import { string } from 'yup'
import { Activity } from 'shared/models/Activity'
import { isNil } from 'lodash'
import { TagKeys } from 'services/RequestInterfaces/Tag/Tag.interface'
import * as paths from 'routing/Paths'
import { downloadBlobToDevice } from 'shared/utility'

const DefaultAlbumsPerPage = 70

const DefaultPhotographsPerPage = 18

const LocalStorageKeys = {
  EventPasswords: 'event_passwords',
}

export default class EventDetailsStore {
  public eventId: string
  public event: Event
  public albums: Album[]
  public albumsPerPage: number
  public albumsCount: number
  public photographs: Photograph[]
  public tagIds: string[]
  public tagValue: InputStore<string>
  public isFilteringByFaceRecognition: boolean
  public albumsView: boolean
  public photographCount: number
  public dateRange: [string | null, string | null]
  public photographDownloadResult: { success: boolean; error?: string } | null
  public isLoadingEventDetails: boolean
  public isLoadingEventPhotographs: boolean
  public isLoadingEventAlbums: boolean
  public isLoadingFreePhotograph: boolean
  public isLoadingEventActivities: boolean
  public eventNotFound: boolean
  public isAuthenticated: boolean
  public password?: string
  public page: number
  public photographsPerPage: number
  public downloadingPhotographIds: Set<string> = new Set()
  public error: any
  public activity: Activity | null
  public activities: Activity[]
  private tagKey?: TagKeys
  private readonly feedService: FeedService
  private readonly eventService: EventService
  private readonly authStore: AuthStore
  private ownerId?: string
  constructor(
    private readonly eventLandingPath: string,
    authStore: AuthStore,
    initialTagValue?: string | null,
    ownerId?: string
  ) {
    this.resetStore(initialTagValue)
    makeAutoObservable(this)
    this.authStore = authStore
    this.eventService = new EventService()
    this.feedService = new FeedService()
    this.ownerId = ownerId
  }
  resetStore(initialTagValue?: string | null) {
    this.eventId = ''
    this.event = new Event()
    this.eventNotFound = false
    this.albums = []
    this.albumsCount = 0
    this.albumsPerPage = DefaultAlbumsPerPage
    this.photographsPerPage = DefaultPhotographsPerPage
    this.photographs = []
    this.tagIds = []
    this.tagValue = new InputStore(string().optional())
    if (initialTagValue) {
      this.tagValue.setValue(initialTagValue)
    }
    this.tagKey = undefined
    this.isFilteringByFaceRecognition = false
    this.albumsView = false
    this.activities = []
    this.activity = null
    this.photographCount = 0
    this.dateRange = [null, null]
    this.photographDownloadResult = null
    this.isLoadingEventDetails = false
    this.isLoadingEventPhotographs = false
    this.isLoadingEventAlbums = false
    this.isLoadingEventActivities = false
    this.isLoadingFreePhotograph = false
    this.page = 1
    this.error = null
    this.downloadingPhotographIds = new Set()

    const password = this.getLocalStoragePassword()
    this.isAuthenticated = !!password
    this.password = password
  }
  //FETCH FUNCTIONS
  async fetchEventDetails(): Promise<void> {
    this.startLoadingEventDetails()
    try {
      const response = await this.feedService.fetchEventDetailsInformationByLandingPath(
        this.eventLandingPath
      )
      runInAction(() => {
        this.eventId = response.id
        this.event = response
        if (this.event.isPrivate) {
          const password = this.getLocalStoragePassword()
          this.isAuthenticated = !!password
          this.password = password
        }
        if (isNil(this.event.activityId)) {
          this.fetchEventActivities()
        }
        this.stopLoadingEventDetails()
      })
    } catch (e: any) {
      this.eventNotFound = e.message === 'Event not found'
      this.stopLoadingEventDetails()
    }
  }

  async fetchEventAlbums() {
    this.startLoadingEventAlbums()

    const limit = this.albumsPerPage
    const offset = (this.page - 1) * this.albumsPerPage

    try {
      const response = await this.feedService.fetchFeedAlbums({
        limit: limit,
        offset: offset,
        order: {
          field: FeedOrderFields.TAKEN_DATE,
          sort: FeedOrderSorts.DESC,
        },
        eventId: this.event.id,
        includeHiddenEventAlbums: true,
        activityId: this.activity?.id ?? undefined,
        dateFrom: this.dateRange[0] ?? undefined,
        dateTo: this.dateRange[1] ?? undefined,
        password: this.password ?? undefined,
        ownerId: this.ownerId ?? undefined,
      })

      runInAction(() => {
        if (this.page === 1) {
          this.albums = response.albums
        } else {
          this.albums = this.albums.concat(response.albums)
        }
        this.albumsCount = response.count
        this.stopLoadingEventAlbums()
      })
    } catch (e) {
      this.stopLoadingEventAlbums()
      this.error = e
    }
  }

  public async fetchEventPhotographs(isSearching = false, tagValue?: string): Promise<void> {
    this.startLoadingEventPhotographs()
    if (isSearching) {
      this.page = 1
      this.photographs = []
    }

    if (tagValue) {
      const response = await this.feedService.fetchTag(tagValue)
      if (response) {
        this.tagIds = [response.id]
        this.tagKey = response.key
      } else {
        this.tagIds = []
        this.tagKey = undefined
        this.photographs = []
        this.photographCount = 0
        this.stopLoadingEventPhotographs()
        return
      }
    } else if (!this.isFilteringByFaceRecognition) {
      this.tagIds = []
      this.tagKey = undefined
    }

    const useRecognitionUrl =
      this.page === 1 && this.isFilteringByFaceRecognition && this.tagIds.length === 0

    try {
      const response = await this.eventService.fetchEventPhotographs(
        this.event.id,
        this.photographsPerPage,
        (this.page - 1) * this.photographsPerPage,
        this.tagIds[0],
        tagValue,
        this.tagKey,
        useRecognitionUrl ? this.authStore.recognitionImageUrl : undefined,
        this.activity?.id ?? undefined,
        this.dateRange[0] ?? undefined,
        this.dateRange[1] ?? undefined,
        this.authStore.isAuthenticated() ? this.authStore.getLoggedUser().id : undefined,
        this.password ?? undefined,
        this.ownerId
      )

      if (
        this.ownerId &&
        response.items.length === 0 &&
        (tagValue || this.isFilteringByFaceRecognition || this.activity || this.dateRange[0])
      ) {
        this.clearOwnerFilter()
        return
      }

      runInAction(() => {
        this.photographs = isSearching ? response.items : this.photographs.concat(response.items)
        this.photographCount = response.count
        if (!isNil(response.tag)) {
          this.tagIds = [response.tag.id]
          this.tagKey = response.tag.key
        }
        this.setIsAuthenticated(true)
        this.stopLoadingEventPhotographs()
        this.updatePrivateEventAuth(this.password, true)
      })
    } catch (e: any) {
      if (e.message == 'INVALID_CREDENTIALS') {
        this.updatePrivateEventAuth(undefined, false)
      }
      this.stopLoadingEventPhotographs()
      throw e
    }
  }

  public setPassword(password: string | undefined) {
    this.password = password
  }

  public getLocalStoragePassword(): string | undefined {
    const passwords = localStorage.getItem(LocalStorageKeys.EventPasswords)
    const eventPasswords = passwords ? JSON.parse(passwords) : {}
    return eventPasswords[this.event.id] || undefined
  }

  private updatePrivateEventAuth(password: string | undefined, authenticate: boolean) {
    if (!isNil(password) && authenticate) {
      this.password = password
      const passwords = localStorage.getItem(LocalStorageKeys.EventPasswords)
      const eventPasswords = passwords ? JSON.parse(passwords) : {}
      eventPasswords[this.event.id] = password
      localStorage.setItem(LocalStorageKeys.EventPasswords, JSON.stringify(eventPasswords))
      this.isAuthenticated = true
    } else {
      this.isAuthenticated = false
      this.password = undefined
      const passwords = localStorage.getItem(LocalStorageKeys.EventPasswords)
      const eventPasswords = passwords ? JSON.parse(passwords) : {}
      delete eventPasswords[this.event.id]
      localStorage.setItem(LocalStorageKeys.EventPasswords, JSON.stringify(eventPasswords))
    }
  }

  async fetchEventActivities() {
    this.startLoadingEventActivities()
    try {
      const response = await this.eventService.fetchEventActivities(this.event.id)
      runInAction(() => {
        this.activities = response
        this.stopLoadingEventActivities()
      })
    } catch (e) {
      this.stopLoadingEventActivities()
      this.error = e
    }
  }

  async updatePhotographCount(albumPhotographs: number) {
    this.photographCount = this.photographCount + albumPhotographs
  }

  async clearPhotographCount() {
    this.photographCount = 0
  }

  // MODIFICATION FUNCTIONS
  startLoadingEventDetails() {
    this.isLoadingEventDetails = true
  }
  stopLoadingEventDetails() {
    this.isLoadingEventDetails = false
  }
  startLoadingEventPhotographs() {
    this.isLoadingEventPhotographs = true
  }
  stopLoadingEventPhotographs() {
    this.isLoadingEventPhotographs = false
  }
  startLoadingEventAlbums() {
    this.isLoadingEventAlbums = true
  }
  stopLoadingEventAlbums() {
    this.isLoadingEventAlbums = false
  }

  startLoadingEventActivities() {
    this.isLoadingEventActivities = true
  }
  stopLoadingEventActivities() {
    this.isLoadingEventActivities = false
  }

  setPage(page: number) {
    if (page < 1) {
      throw Error(`Page number can't be less than 1`)
    }
    this.page = page
    if (this.albumsView) {
      this.fetchEventAlbums()
    } else {
      this.fetchEventPhotographs(false, this.tagValue.value)
    }
  }

  setDateRange(range: [string | null, string | null]) {
    runInAction(() => {
      this.dateRange = range
    })
  }

  setTagValue(value: string) {
    this.tagValue.setValue(value)
  }
  setActivity(activityName: string | undefined) {
    const activity = this.activities.find((activity) => activity.name === activityName) || null
    this.activity = activity
  }
  setIsFilteringByFaceRecognition(value: boolean) {
    this.isFilteringByFaceRecognition = value
    this.clearPhotographCount()
  }

  setAlbumsView(value: boolean) {
    this.albumsView = value
    this.clearPhotographCount()
  }

  setIsAuthenticated(value: boolean) {
    this.isAuthenticated = value
  }

  setOwnerId(ownerId?: string) {
    this.ownerId = ownerId
  }

  fetchNextPage() {
    this.setPage(this.page + 1)
  }

  hasMorePages() {
    const pageCount = Math.ceil(this.photographCount / this.photographsPerPage)
    return this.page < pageCount
  }

  hasMoreAlbumPages() {
    const pageCount = Math.ceil(this.albumsCount / this.albumsPerPage)
    return this.page < pageCount
  }

  async downloadFreePhotograph(photographId: string) {
    this.startLoadingFreePhotographs(photographId)
    try {
      const result = await this.feedService.downloadFreePhotograph(photographId)

      if (!result.success) {
        throw result.error || new Error('Download failed')
      }

      if (result.blob) {
        try {
          const downloaded = await downloadBlobToDevice(
            result.blob,
            `lumepic-photograph-${photographId}.jpg`
          )
          runInAction(() => {
            this.stopLoadingFreePhotographs(photographId)
            this.photographDownloadResult = {
              success: downloaded,
            }
          })
          return { success: downloaded }
        } catch (error) {
          runInAction(() => {
            this.stopLoadingFreePhotographs(photographId)
            this.photographDownloadResult = {
              success: false,
            }
          })
          return { success: false }
        }
      }
      return { success: false }
    } catch (error) {
      runInAction(() => {
        this.stopLoadingFreePhotographs(photographId)
        this.photographDownloadResult = {
          success: false,
        }
      })
      return { success: false }
    }
  }

  public getOwnerId(): string | undefined {
    return this.ownerId
  }

  public getOwnerIdAlias(): string | undefined {
    const album = this.albums.find((album) => album.owner.id === this.ownerId)
    return album?.owner.alias
  }

  public clearOwnerFilter() {
    this.ownerId = undefined

    const currentTagValue = this.tagValue.value
    const setIsFilteringByFaceRecognition = this.isFilteringByFaceRecognition
    const currentActivity = this.activity
    const currentDateRange = this.dateRange

    const url = paths.feedEventDetailsByLandingPath(this.event.landingPath)
    const params = new URLSearchParams()

    if (currentTagValue) {
      params.set('tagValue', currentTagValue)
    }
    if (setIsFilteringByFaceRecognition) {
      params.set('faceRecognition', 'true')
    }
    if (currentActivity) {
      params.set('activity', currentActivity.id)
    }
    if (currentDateRange[0]) {
      params.set('dateFrom', currentDateRange[0])
    }
    if (currentDateRange[1]) {
      params.set('dateTo', currentDateRange[1])
    }

    const queryString = params.toString()
    const finalUrl = queryString ? `${url}?${queryString}` : url
    window.history.replaceState(null, '', finalUrl)

    this.fetchEventAlbums()
    this.fetchEventPhotographs(true, currentTagValue)
  }

  @action
  resetPhotographDownloadResult() {
    this.photographDownloadResult = null
  }

  startLoadingFreePhotographs(photographId: string) {
    this.isLoadingFreePhotograph = true
    this.downloadingPhotographIds.add(photographId)
  }

  stopLoadingFreePhotographs(photographId: string) {
    this.isLoadingFreePhotograph = false
    this.downloadingPhotographIds.delete(photographId)
  }
}
