import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Album from '../../../../../shared/models/Album'
import 'moment/locale/es'
import moment from 'moment'
import Breadcrumb from 'components/UI/Breadcrumb'
import { isNil } from 'lodash'
import ShareModalContent from 'components/UI/ShareModal/components/ShareModalContent'
import ConfigService from 'config'

type PublishAlbumModalProps = {
  album: Album
}

const PublishAlbum = ({ album }: PublishAlbumModalProps) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)

  return (
    <div className="flex flex-col w-full items-center justify-center my-3 gap-4">
      <div className="mt-2 flex flex-col items-center gap-2.5">
        <div className="mb-3">
          <Breadcrumb
            completedSteps={!isNil(album.event?.quantityDiscountId) ? 4 : 5}
            totalSteps={!isNil(album.event?.quantityDiscountId) ? 4 : 5}
          />
        </div>
        <div className="text-2xl font-semibold text-center">{t('New Album Created!')}</div>
      </div>
      <div className="w-2/3 text-lumepic-grey text-center font-normal">
        {t('The album') +
          " '" +
          album.location.spotName +
          ' ' +
          moment.utc(album.takenDate).format('dddd DD/MM/YYYY') +
          "' " +
          t('was created successfully.')}
      </div>
      <div className="mb-2 text-lumepic-light_black w-full text-center text-xl font-medium">
        {t('Share for more visibility!')}
      </div>
      <ShareModalContent
        isCopied={isCopied}
        setIsCopied={setIsCopied}
        shareUrl={ConfigService.buildAlbumUrl(album.id ?? '')}
        shareTitle={'Share'}
        shareDescription={'Check out this album on Lumepic!'}
      />
    </div>
  )
}

export default observer(PublishAlbum)
