import { action, makeAutoObservable, runInAction } from 'mobx'
import FeedService from '../../services/FeedService'
import Album from '../../shared/models/Album'
import AuthStore from '../../stores/AuthStore'
import { Photograph } from '../../shared/models/Photograph'
import { Tag } from 'shared/models/Tag'
import { TagKeys } from 'services/RequestInterfaces/Tag/Tag.interface'
import { isNil } from 'lodash'
import InputStore from 'shared/store/InputStore'
import { string } from 'yup'
import { ItemDetailsStoreInterface } from 'components/ItemDetails/Interfaces/ItemDetailsStore.interface'
import { downloadBlobToDevice } from 'shared/utility'
const DefaultPhotographsPerPage = 18

enum LocalStorageKeys {
  AlbumPasswords = 'album_passwords',
  EventPasswords = 'event_passwords',
}

export default class AlbumDetailsStore implements ItemDetailsStoreInterface {
  public album: Album
  public photographs: Photograph[]
  public albumTags: Tag[] | undefined
  public tagIds: string[]
  public tagValue: InputStore<string>
  public isFilteringByFaceRecognition: boolean
  public dateRange: [string | null, string | null]
  public photographCount: number
  public photographDownloadResult: { success: boolean; error?: string } | null
  public isLoadingAlbumDetails: boolean
  public isLoadingAlbumTags: boolean
  public isLoadingAlbumPhotographs: boolean
  public isLoadingFreePhotograph: boolean
  public albumNotFound: boolean
  public page: number
  public photographsPerPage: number
  public filterErrors: any
  public isAuthenticated: boolean
  public password?: string
  public downloadingPhotographIds: Set<string> = new Set()
  private tagKey?: TagKeys
  private readonly feedService: FeedService
  private readonly authStore: AuthStore

  constructor(
    private readonly albumId: string,
    authStore: AuthStore,
    initialTagValue?: string | null
  ) {
    this.resetStore(initialTagValue)
    makeAutoObservable(this)
    this.authStore = authStore
    this.feedService = new FeedService()
  }

  resetStore(initialTagValue?: string | null) {
    this.albumNotFound = false
    this.isFilteringByFaceRecognition = false
    this.album = new Album()
    this.photographs = []
    this.albumTags = undefined
    this.tagIds = []
    this.tagValue = new InputStore(string().optional())
    if (initialTagValue) {
      this.tagValue.setValue(initialTagValue)
    }
    this.tagKey = undefined
    this.dateRange = [null, null]
    this.photographCount = 0
    this.isLoadingAlbumDetails = false
    this.isLoadingAlbumTags = false
    this.isLoadingAlbumPhotographs = false
    this.isLoadingFreePhotograph = false
    this.page = 1
    this.photographsPerPage = DefaultPhotographsPerPage
    this.filterErrors = null
    const password = !isNil(this.album.event.id)
      ? this.getPassword(this.albumId, this.album.event.id)
      : this.getPassword(this.albumId)
    this.isAuthenticated = !!password
    this.password = password
    this.photographDownloadResult = null
    this.downloadingPhotographIds = new Set()
  }

  //FETCH FUNCTIONS
  async fetchAlbumDetails(): Promise<void> {
    this.startLoadingAlbumDetails()
    try {
      const response = await this.feedService.fetchAlbumDetails(this.albumId)
      runInAction(() => {
        this.album = response
        if (!isNil(this.album.event.id) && this.album.isPrivate) {
          const password = this.getPassword(this.albumId, this.album.event.id)
          this.isAuthenticated = !!password
          this.password = password
        }
        this.stopLoadingAlbumDetails()
      })
    } catch (e: any) {
      this.albumNotFound = e.message === 'Album not found'
      this.stopLoadingAlbumDetails()
    }
  }

  async fetchAlbumTags(): Promise<void> {
    runInAction(() => {
      this.isLoadingAlbumTags = true
    })
    try {
      const response = await this.feedService.fetchAlbumTags(this.albumId)
      runInAction(() => {
        this.albumTags = response
        this.isLoadingAlbumTags = false
      })
    } catch (e: any) {
      this.albumNotFound = e.message === 'Album not found'
      this.isLoadingAlbumTags = false
    }
  }

  async fetchPhotograph(albumId: string, photographId: string, password?: string): Promise<void> {
    runInAction(() => {
      this.startLoadingAlbumPhotographs()
      this.photographs = []
    })
    try {
      const photograph = await this.feedService.fetchPhotograph(
        albumId,
        photographId,
        password ?? this.password ?? undefined
      )
      runInAction(() => {
        this.savePassword(password, albumId)
        this.setIsAuthenticated(true)
        this.stopLoadingAlbumPhotographs()
        this.photographs = [photograph]
        if (!isNil(password) && isNil(this.password)) {
          this.password = password
        }
      })
    } catch (e) {
      this.stopLoadingAlbumPhotographs()
    }
  }

  public getPassword(albumId: string, eventId?: string): string | undefined {
    const key = eventId ? LocalStorageKeys.EventPasswords : LocalStorageKeys.AlbumPasswords
    const passwords = localStorage.getItem(key)
    const parsedPasswords = passwords ? JSON.parse(passwords) : {}
    return parsedPasswords[eventId || albumId]
  }

  private savePassword(password: string | undefined, albumId: string) {
    if (!isNil(password)) {
      const passwords = localStorage.getItem(LocalStorageKeys.AlbumPasswords)
      const albumPasswords = passwords ? JSON.parse(passwords) : {}
      albumPasswords[albumId] = password
      localStorage.setItem(LocalStorageKeys.AlbumPasswords, JSON.stringify(albumPasswords))
    }
  }

  async fetchPhotographs(isSearching = false, tagValue?: string, password?: string): Promise<void> {
    this.startLoadingAlbumPhotographs()
    if (isSearching) {
      this.page = 1
      this.photographs = []
    }

    if (tagValue) {
      const response = await this.feedService.fetchTag(tagValue)
      if (response) {
        this.tagIds = [response.id]
        this.tagKey = response.key
      } else {
        this.tagIds = []
        this.tagKey = undefined
        this.photographs = []
        this.photographCount = 0
        this.stopLoadingAlbumPhotographs()
        return
      }
    } else if (!this.isFilteringByFaceRecognition) {
      this.tagIds = []
      this.tagKey = undefined
    }

    const limit = this.photographsPerPage
    const offset = (this.page - 1) * this.photographsPerPage

    const useRecognitionUrl =
      this.page === 1 && this.isFilteringByFaceRecognition && this.tagIds.length === 0

    try {
      const response = await this.feedService.fetchAlbumPhotographs(
        this.albumId,
        limit,
        offset,
        this.tagIds[0],
        this.tagValue.value,
        this.tagKey,
        useRecognitionUrl ? this.authStore.recognitionImageUrl : undefined,
        this.dateRange[0] ?? undefined,
        this.dateRange[1] ?? undefined,
        this.authStore.isAuthenticated() ? this.authStore.getLoggedUser().id : undefined,
        this.password ?? undefined
      )
      runInAction(() => {
        if (isSearching) {
          this.photographs = response.items
        } else {
          this.photographs.push(...response.items)
        }
        if (!isNil(password) && isNil(this.password)) {
          this.password = password
        }
        this.photographCount = response.count
        if (!isNil(response.tag)) {
          this.tagIds = [response.tag.id]
          this.tagKey = response.tag.key
        }
        this.setIsAuthenticated(true)
        this.stopLoadingAlbumPhotographs()
      })
    } catch (e) {
      this.stopLoadingAlbumPhotographs()
    }
  }

  // MODIFICATION FUNCTIONS
  setIsAuthenticated(val: boolean) {
    this.isAuthenticated = val
  }

  startLoadingAlbumDetails() {
    this.isLoadingAlbumDetails = true
  }

  stopLoadingAlbumDetails() {
    this.isLoadingAlbumDetails = false
  }

  startLoadingAlbumPhotographs() {
    this.isLoadingAlbumPhotographs = true
  }

  stopLoadingAlbumPhotographs() {
    this.isLoadingAlbumPhotographs = false
  }

  startLoadingFreePhotographs(photographId: string) {
    this.isLoadingFreePhotograph = true
    this.downloadingPhotographIds.add(photographId)
  }

  stopLoadingFreePhotographs(photographId: string) {
    this.isLoadingFreePhotograph = false
    this.downloadingPhotographIds.delete(photographId)
  }

  setPage(page: number) {
    if (page < 1) {
      throw Error(`Page number can't be less than 1`)
    }
    this.page = page
    this.fetchPhotographs(false, this.tagValue.value)
  }

  public setPassword(password: string | undefined) {
    this.password = password
  }

  setTagValue(value: string) {
    this.tagValue.setValue(value)
  }

  setDateRange(range: [string | null, string | null]) {
    runInAction(() => {
      this.dateRange = range
    })
  }

  async clearPhotographCount() {
    this.photographCount = 0
  }

  setIsFilteringByFaceRecognition(value: boolean) {
    this.isFilteringByFaceRecognition = value
  }

  async downloadFreePhotograph(photographId: string) {
    this.startLoadingFreePhotographs(photographId)
    try {
      const result = await this.feedService.downloadFreePhotograph(photographId)

      if (!result.success) {
        throw result.error || new Error('Download failed')
      }

      if (result.blob) {
        try {
          const downloaded = await downloadBlobToDevice(
            result.blob,
            `lumepic-photograph-${photographId}.jpg`
          )
          runInAction(() => {
            this.stopLoadingFreePhotographs(photographId)
            this.photographDownloadResult = {
              success: downloaded,
              error: downloaded ? undefined : 'Download cancelled by user',
            }
          })
          return { success: downloaded }
        } catch (error) {
          runInAction(() => {
            this.stopLoadingFreePhotographs(photographId)
            this.photographDownloadResult = {
              success: false,
              error: error instanceof Error ? error.message : 'Failed to download',
            }
          })
          return { success: false, error }
        }
      }
      return { success: false }
    } catch (error) {
      runInAction(() => {
        this.stopLoadingFreePhotographs(photographId)
        this.photographDownloadResult = {
          success: false,
          error: error instanceof Error ? error.message : 'Unknown error',
        }
      })
      return { success: false, error }
    }
  }

  fetchNextPage() {
    this.setPage(this.page + 1)
  }
  hasMorePages() {
    const pageCount = Math.ceil(this.photographCount / this.photographsPerPage)
    return this.page < pageCount
  }

  @action
  resetPhotographDownloadResult() {
    this.photographDownloadResult = null
  }
}
