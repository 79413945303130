import React from 'react'
import { faCheck, faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FacebookShareButton from 'components/UI/ShareButtons/FacebookShareButton'
import WhatsappShareButton from 'components/UI/ShareButtons/WhatsappShareButton'
import XShareButton from 'components/UI/ShareButtons/XShareButton'
import { useTranslation } from 'react-i18next'
import { WhatsappIcon } from 'react-share'
import { isNil } from 'lodash'

type ShareModalContentProps = {
  isCopied: boolean
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>
  shareUrl: string
  shareTitle: string
  shareDescription?: string
}

const ShareModalContent = ({
  shareUrl,
  shareTitle,
  shareDescription,
  isCopied,
  setIsCopied,
}: ShareModalContentProps) => {
  const { t } = useTranslation()

  const handleCopyUrlClick = () => {
    setIsCopied(true)
    navigator.clipboard.writeText(shareUrl)
  }

  return (
    <div className="flex flex-col w-full items-center justify-center my-3 gap-4 pb-8">
      {!isNil(shareTitle) && (
        <div className="text-2xl font-semibold text-center">{t(shareTitle)}</div>
      )}
      {!isNil(shareDescription) && (
        <div className="text-center text-lumepic-grey px-4 mb-2">{t(shareDescription)}</div>
      )}

      <div className="flex flex-col">
        <div className="flex justify-center gap-4 mt-2.5 items-center">
          <div className="flex flex-col gap-1 items-center justify-center">
            <div
              onClick={() => handleCopyUrlClick()}
              style={{ height: '58px', width: '58px' }}
              className="flex flex-col justify-center items-center rounded-full bg-lumepic-medium_grey cursor-pointer hover:sm:bg-stone-300 transition duration-200"
            >
              <FontAwesomeIcon icon={!isCopied ? faLink : faCheck} className="text-2xl" />
            </div>
            <span className="text-sm">{!isCopied ? t('Link') : t('Copied')}</span>
          </div>
          <div className="flex flex-col gap-1 items-center justify-center">
            <FacebookShareButton url={shareUrl} iconSize={60} />
            <span className="text-sm">Facebook</span>
          </div>
          <div className="flex flex-col gap-1 items-center justify-center">
            <XShareButton url={shareUrl} iconSize={60} />
            <span className="text-sm">X</span>
          </div>
          <div className="flex flex-col gap-1 items-center justify-center">
            <WhatsappShareButton url={shareUrl} title={shareTitle}>
              <WhatsappIcon size={60} round={true} enableBackground="true" />
            </WhatsappShareButton>
            <span className="text-sm">Whatsapp</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareModalContent
