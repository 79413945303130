import React, { useContext, useState } from 'react'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import backgroundImage from '../../../assets/images/goldenBg1.svg'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import storesContext from 'providers/storesContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import SelfieUploader from 'components/UI/SelfieUploader/SelfieUploader'
import ProfileStore from 'containers/MyAccount/components/UpdateUser/ProfileStore'
import AlbumDetailsStore from '../AlbumDetailsStore'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import Button from 'components/UI/Button'

type FaceRecognitionModalProps = {
  onCancel: () => void
  opened: boolean
  store: AlbumDetailsStore | EventDetailsStore
  onSuccess: () => void
}

const FaceRecognitionModal = ({
  onCancel,
  opened,
  store,
  onSuccess,
}: FaceRecognitionModalProps) => {
  const { t } = useTranslation()
  const { authStore } = useContext(storesContext)!
  const [profileStore] = useState(() => new ProfileStore(authStore, true))
  const [selectedAsProfilePicture, setSelectedAsProfilePicture] = useState(false)

  const filterByFaceRecognition = () => {
    store.setIsFilteringByFaceRecognition(true)
    onSuccess()
    onCancel()
  }

  const handleFinish = () => {
    if (authStore.isAuthenticated() && selectedAsProfilePicture) {
      profileStore.uploadImage().then(() => {
        const loggedUser = authStore.getLoggedUser()
        loggedUser.profilePictureUrl = profileStore.profilePictureUrl!
        profileStore.updateUser(loggedUser)
      })
    }
    filterByFaceRecognition()
  }

  return (
    <Modal
      onCancel={onCancel}
      opened={opened}
      modalSize={ModalSize.MEDIUM_SMALL}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      displayCloseIcon={false}
    >
      <div className="w-full">
        <div className="relative overflow-hidden h-14 rounded-t-md">
          <img src={backgroundImage} className="w-full" alt="background" />
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="absolute text-xl cursor-pointer top-2 right-2"
            onClick={() => onCancel()}
          />
        </div>
        <div className="relative h-auto">
          <div className="absolute w-full text-center -top-6">
            <div className="flex flex-col justify-center gap-2">
              <h3 className="mb-0 text-center">
                <span className="p-1 text-2xl font-medium bg-white rounded-md shadow-md">
                  {t('Upload selfie')}
                </span>
              </h3>
              <span className="text-xs px-2">
                {t('Upload a selfie to filter the photos in the album that match your face!')}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full rounded-b-md pt-12 pb-0.5">
          <div className="w-full flex justify-center">
            <div className="sm:w-1/2">
              <SelfieUploader
                setSelectedAsProfilePicture={setSelectedAsProfilePicture}
                selectedAsProfilePicture={selectedAsProfilePicture}
                store={profileStore}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center mt-2">
          <Button
            btnType="PrimaryAction"
            onClick={profileStore.isLoading || authStore.isLoading ? () => {} : handleFinish}
            extraStyle="mb-2"
            disabled={
              (profileStore.isLoading || authStore.isLoading) &&
              (!selectedAsProfilePicture || !profileStore.imageSubmitted)
            }
          >
            {t('Save and filter')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default observer(FaceRecognitionModal)
