import * as yup from 'yup'

const photographerRegisterFormValidationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  countryCode: yup.string().required('Country is required'),
  alias: yup
    .string()
    .required('Alias is required')
    .matches(
      /^[a-z0-9._-]+$/,
      'Only lowercase letters, numbers, periods, hyphens, and underscores are allowed'
    )
    .max(30, 'The alias must have a maximum of 30 characters'),
})

export default photographerRegisterFormValidationSchema
