import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from '../../../../stores/AuthStore'
import UserService from 'services/UserService'
import { User } from 'shared/models/User'
import { ProgressInfo } from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/UploadImageToAlbum/UploadImageToAlbumStore'
import { isNil } from 'lodash'
import FileService from 'services/FileService'
import UpdateUserInterface from 'services/RequestInterfaces/User/UpdateUser.interface'
import i18n from 'i18next'
import UploadProfilePictureResponse from 'services/RequestInterfaces/User/UploadProfilePictureResponse'

class ProfileStore {
  public profilePictureUrl: string | null
  public progressInfo: ProgressInfo | undefined
  public imageSubmitted: boolean
  public uploadingImage: boolean
  public image: File | undefined
  public imageLoaded: boolean
  public isLoading: boolean
  public error: any
  public serverError: string
  private s3UploadKeyAndUrl: UploadProfilePictureResponse | undefined
  private userService: UserService
  private fileService: FileService
  constructor(private readonly authStore: AuthStore, public isRecognitionImage: boolean) {
    this.reset()
    makeAutoObservable(this)
    this.userService = new UserService()
    this.fileService = new FileService()
    this.imageLoaded = false
    this.getProfilePictureUrl(isRecognitionImage)
  }
  phoneRegExp = RegExp(/^[0-9]{9,13}$/)
  lowercaseAndNumbersRegex = /^[a-z0-9._-]+$/

  reset() {
    this.profilePictureUrl = null
    this.s3UploadKeyAndUrl = undefined
    this.image = undefined
    this.imageSubmitted = false
    this.progressInfo = undefined
    this.uploadingImage = false
    this.error = false
    this.isLoading = false
    this.imageLoaded = false
  }

  setDefaultProfilePicture(user: UpdateUserInterface) {
    this.profilePictureUrl = user.profilePictureUrl || null
  }

  async updateUser(updateUserPayload: UpdateUserInterface): Promise<User | undefined> {
    runInAction(() => {
      this.isLoading = true
    })
    if (!isNil(this.profilePictureUrl)) {
      updateUserPayload.profilePictureUrl = this.profilePictureUrl
    }
    try {
      const user = await this.userService.updateUser(
        this.authStore.getToken(),
        this.authStore.getLoggedUser().id,
        updateUserPayload
      )

      runInAction(() => {
        this.isLoading = false
      })
      return user
    } catch (e: any) {
      runInAction(() => {
        this.isLoading = false
        this.error = e
      })
      return
    }
  }

  async getProfilePictureUrl(isRecognitionImage?: boolean): Promise<void> {
    try {
      let response
      if (!isRecognitionImage) {
        response = await this.userService.createProfilePicture(
          this.authStore.getLoggedUser().id,
          this.authStore.getToken()
        )
      } else if (this.authStore.isAuthenticated()) {
        response = await this.userService.createPermanentRecognitionPicture(
          this.authStore.getLoggedUser().id,
          this.authStore.getToken()
        )
      } else {
        if (!isNil(this.authStore.randomUuid)) {
          this.authStore.checkAuthState()
        }
        response = await this.userService.createTemporaryRecognitionPicture(
          this.authStore.randomUuid
        )
      }

      this.s3UploadKeyAndUrl = response
    } catch (e: any) {
      throw new Error('There was an error getting the upload key and url')
    }
  }

  uploadImageFail() {
    const _progressInfo = this.progressInfo
    _progressInfo!.error = true
    _progressInfo!.percentage = 100
    this.changeProgressInfo(_progressInfo!)
    this.error = null
    this.uploadingImage = false
  }

  isUploadComplete() {
    return (
      (!isNil(this.progressInfo) &&
        this.progressInfo.percentage === 100 &&
        !this.progressInfo.error) ||
      (isNil(this.progressInfo) && (this.profilePictureUrl || this.authStore.recognitionImageUrl))
    )
  }

  async uploadImage(isRecognitionImage?: boolean): Promise<void> {
    runInAction(() => {
      this.isLoading = true
      this.uploadImageToUserStart()
    })

    if (!isRecognitionImage) {
      this.profilePictureUrl = null
    }

    if (isNil(this.s3UploadKeyAndUrl)) {
      try {
        await this.getProfilePictureUrl(isRecognitionImage)
      } catch (e) {
        this.uploadImageFail()
        this.isLoading = false
        return
      }
    }

    if (!isNil(this.s3UploadKeyAndUrl)) {
      if (!isRecognitionImage) {
        this.profilePictureUrl = this.s3UploadKeyAndUrl.photographUrl
      } else {
        this.authStore.changeRecognitionImage(this.s3UploadKeyAndUrl.photographUrl)
      }

      try {
        await this.fileService.uploadFileToUrl(
          this.image!,
          this.s3UploadKeyAndUrl.uploadUrl,
          this.s3UploadKeyAndUrl.uploadKey,
          (event: any) => {
            runInAction(() => {
              const _progressInfo = this.progressInfo
              _progressInfo!.percentage = Math.round((100 * event.loaded) / event.total)
              _progressInfo!.requestSent = true
              this.changeProgressInfo(_progressInfo!)
              if (_progressInfo!.percentage === 100) {
                this.isLoading = false
                this.s3UploadKeyAndUrl = undefined
              }
            })
          },
          () => {
            runInAction(() => {
              this.uploadImageFail()
              this.isLoading = false
            })
          }
        )
      } catch (e: any) {
        runInAction(() => {
          this.uploadImageFail()
          this.isLoading = false
          this.s3UploadKeyAndUrl = undefined
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})
          if (!displayedError) {
            this.serverError = i18n.t(
              'Something went wrong, please check the provided data and try again'
            )
          }
        })
      }
    } else {
      runInAction(() => {
        this.isLoading = false
        this.s3UploadKeyAndUrl = undefined
        this.uploadImageFail()
      })
    }
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    const displayedError = false
    keys.forEach((key) => {
      switch (key) {
        default:
          break
      }
    })
    return displayedError
  }

  changeImageSubmitted(val: boolean) {
    this.imageSubmitted = val
  }
  changeImage(val: File) {
    this.image = val
  }
  changeProgressInfo(val: ProgressInfo) {
    this.progressInfo = val
  }
  uploadImageToUserStart() {
    this.error = null
    this.uploadingImage = true
  }

  startLoading() {
    this.isLoading = true
  }

  stopLoading() {
    this.isLoading = false
  }

  setImageLoaded(value: boolean) {
    this.imageLoaded = value
  }
}
export default ProfileStore
