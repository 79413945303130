import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Modal from '../../components/UI/Modal'
import Typography from '@mui/material/Typography'
import Button from 'components/UI/Button'
import { Event } from 'shared/models/Event'
import backgroundImage from '../../assets/images/goldenBg1.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { ModalSize } from 'components/UI/Modal/Modal'
import { ModalButtonsDisplay } from 'components/UI/Modal/Modal'
import { isNil } from 'lodash'
import EventsStore from './EventsStore'
import EventInformation from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/EventAlbumForm/EventInformation'

type EventInformationModalProps = {
  handleClose: () => void
  event: Event
  onAccreditationRequest?: () => void
  isLoading?: boolean
  eventsStore: EventsStore
}

const EventInformationModal = ({
  handleClose,
  event,
  onAccreditationRequest,
  isLoading = false,
  eventsStore,
}: EventInformationModalProps) => {
  const { t } = useTranslation()

  const today = new Date()

  return (
    <Modal
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      opened={true}
      onCancel={handleClose}
      displayCloseIcon={false}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="w-full">
        <div className="relative overflow-hidden h-14 rounded-t-md">
          <img src={backgroundImage} className="w-full" alt="background" />
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="absolute text-xl cursor-pointer top-2 right-2"
            onClick={handleClose}
          />
        </div>
        <div className="relative h-auto">
          <div className="absolute w-full text-center -top-6">
            <div className="flex flex-col justify-center gap-2">
              <h3 className="mb-0 text-center">
                <span className="p-1 text-2xl font-medium bg-white rounded-md shadow-md">
                  {`${t('Event')}: ${event.name}`}
                </span>
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full bg-bg_details rounded-b-md pt-10 pb-6">
          {event.accreditationsEnabled && new Date(event.date) > today && (
            <div className="mx-6 mb-6 p-6 rounded-lg bg-white shadow-sm">
              <h4 className="font-medium mb-4">
                {t('What are accreditations and how do they work?')}
              </h4>
              <ul className="list-disc px-4 space-y-2">
                <li className="font-light">{t('Event_accreditation_info_1')}</li>
                <li className="font-light">{t('Event_accreditation_info_2')}</li>
                <li className="font-light">{t('Event_accreditation_info_3')}</li>
                <li className="font-light">{t('Event_accreditation_info_4')}</li>
              </ul>
              <p className="font-medium m-4">
                {t('For any questions, please contact us at info@lumepic.com')}
              </p>
              {event.accreditationApproved ? (
                <div className="flex justify-center mt-6 pt-4 border-t">
                  <Typography className="text-lumepic-success_dark_green font-light">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-lumepic-success_dark_green font-light mr-1"
                    />
                    {t('Accredited')}
                  </Typography>
                </div>
              ) : (
                <>
                  {isNil(eventsStore.accreditations.find((acc) => acc.eventId === event.id)) ? (
                    <div className="flex justify-center mt-6 pt-4 border-t">
                      <Button
                        onClick={() => {
                          onAccreditationRequest?.()
                          handleClose()
                        }}
                        isLoading={isLoading}
                        loadingText={t('Loading')!}
                        btnType={'SecondaryAction'}
                      >
                        {t('Request Accreditation')}
                      </Button>
                    </div>
                  ) : (
                    <div className="flex justify-center mt-6 pt-4 border-t">
                      <Typography
                        variant="body1"
                        color="text.inherit"
                        fontWeight={500}
                        paddingBottom={1}
                      >
                        {t('Accreditation Requested')}
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-lumepic-success_dark_green font-light ml-1"
                        />
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          <div className="mx-6 p-3 rounded-lg bg-white shadow-sm">
            <EventInformation eventDetails={event} isNewAlbum={false} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(EventInformationModal)
