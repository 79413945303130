import ConfigInterface, { LandingConfigInterface } from './interfaces/config.interface'

const API_URL = 'http://localhost:8080'
const WEB_URL = 'http://localhost:3000'
const USER_CARTS_DATABASE_URL = 'mock-firebase-url/'
const GOOGLE_CLIENT_ID = 'mock-google-client-id'
const GOOGLE_MAPS_API_KEY = 'mock-google-maps-key'
const LAUNCHDARKLY_CLIENT_SIDE_ID = 'mock-launchdarkly-id'
const PRINT_PHOTOGRAPH_REQUEST_FORM_URL = 'http://example.com/form'
const USER_EVENT_PHOTOGRAPHS__REQUEST_FORM = 'http://example.com/event-form'
const EVENT_REQUEST_FORM = 'http://example.com/request-form'
const STRIPE_PAYMENT_URL = 'http://example.com/stripe'
const DATE_LOCALE = 'en'
const IP_API_URL = 'http://example.com/ip-api'
const PERMANENT_USER_UPLOADS_BUCKET = 'http://example.com/uploads'
const MERCADOPAGO_CLIENT_ID = 'mock-mercadopago-id'
const MERCADOPAGO_RESPONSE_TYPE = 'code'
const MERCADOPAGO_PLATFORM_ID = 'mp'
const MERCADOPAGO_REDIRECT_URI = 'http://localhost:8080/my-account'
const WHATSAPP_NUMBER = '1234567890'
const INDEPENDENT_ALBUM_PHOTOGRAPHS_LIMIT = '100'
const EVENT_ALBUM_PHOTOGRAPHS_LIMIT = '200'
const SCROLL_THRESHOLD = '0.8'
const LANDING_CONFIG: LandingConfigInterface = {
  FEATURED_ACTIVITY_1_EN: {
    activityId: 'test-activity-1',
    desktopAssetName: 'http://example.com/desktop1.png',
    mobileAssetName: 'http://example.com/mobile1.png',
  },
  FEATURED_ACTIVITY_1_ES: {
    activityId: 'test-activity-1',
    desktopAssetName: 'http://example.com/desktop1-es.png',
    mobileAssetName: 'http://example.com/mobile1-es.png',
  },
  FEATURED_ACTIVITY_2: {
    activityId: 'test-activity-2',
    desktopAssetName: 'http://example.com/desktop2.png',
    mobileAssetName: 'http://example.com/mobile2.png',
  },
  FEATURED_ACTIVITY_3: {
    activityId: 'test-activity-3',
    desktopAssetName: 'http://example.com/desktop3.png',
    mobileAssetName: 'http://example.com/mobile3.png',
  },
  FEATURED_ACTIVITY_4: {
    activityId: 'test-activity-4',
    desktopAssetName: 'http://example.com/desktop4.png',
    mobileAssetName: 'http://example.com/mobile4.png',
  },
  FEATURED_ACTIVITY_5: {
    activityId: 'test-activity-5',
    desktopAssetName: 'http://example.com/desktop5.png',
    mobileAssetName: 'http://example.com/mobile5.png',
  },
  OTHER_FEATURED_ACTIVITIES: [
    { activityId: 'test-cycling', name: 'Cycling' },
    { activityId: 'test-football', name: 'Football' },
    { activityId: 'test-motoring', name: 'Motoring' },
    { activityId: 'test-crossfit', name: 'Crossfit' },
    { activityId: 'test-basketball', name: 'Basketball' },
    { activityId: 'test-swimming', name: 'Swimming' },
  ],
}

const testConfig: ConfigInterface = {
  API_URL,
  WEB_URL,
  DATE_LOCALE,
  GOOGLE_CLIENT_ID,
  GOOGLE_MAPS_API_KEY,
  WHATSAPP_CONTACT_US_NUMBER: WHATSAPP_NUMBER,
  MERCADOPAGO_CLIENT_ID,
  MERCADOPAGO_RESPONSE_TYPE,
  MERCADOPAGO_PLATFORM_ID,
  MERCADOPAGO_REDIRECT_URI,
  LANDING_CONFIG,
  USER_CARTS_DATABASE_URL,
  LAUNCHDARKLY_CLIENT_SIDE_ID,
  PRINT_PHOTOGRAPH_REQUEST_FORM_URL,
  USER_EVENT_PHOTOGRAPHS__REQUEST_FORM,
  EVENT_REQUEST_FORM,
  STRIPE_PAYMENT_URL,
  PERMANENT_USER_UPLOADS_BUCKET,
  INDEPENDENT_ALBUM_PHOTOGRAPHS_LIMIT,
  EVENT_ALBUM_PHOTOGRAPHS_LIMIT,
  IP_API_URL,
  SCROLL_THRESHOLD,
}

export default testConfig
