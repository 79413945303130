import React, { useContext, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import ScrollToTop from '../components/UI/ScrollToTop'
import SharedRoutes from './Routes/Shared/routes'
import PhotographerRoutes from './Routes/Photographer/routes'
import AthleteRoutes from './Routes/Athlete/routes'
import * as paths from './Paths'
import PhotographerProfile from '../containers/PhotographerProfile/PhotographerProfile'
import StoresContext from '../providers/storesContext'
import { FeatureFlags } from '../config/constants/featureFlags'
import { getFeatureFlagValue } from 'shared/utility'
import MyRewards from 'containers/MyRewards/MyRewards'

type RouterProps = {
  isPhotographer?: boolean
  isAthlete?: boolean
}

const Router = ({ isPhotographer = false, isAthlete = false }: RouterProps) => {
  const { featureFlagsStore } = useContext(StoresContext)!
  let routes: any[] = []
  if (isAthlete && isPhotographer) {
    routes = [...PhotographerRoutes, ...AthleteRoutes]
  } else if (isPhotographer) {
    routes = PhotographerRoutes
  } else {
    routes = AthleteRoutes
  }
  /*
   * ** NOTE **
   * SharedRoutes must be appended to the end of the routes array because if not photographerProfile overrides other routes
   */
  routes = [...routes, ...SharedRoutes]
  useEffect(() => {
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e) => {
      // prevent the right-click menu from appearing
      e.preventDefault()
    }

    // attach the event listener to
    // the document object
    document.addEventListener('contextmenu', handleContextMenu)

    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [])

  const photographerProfileEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.PHOTOGRAPHER_PROFILE,
    true
  )

  const photographerReferralEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.PHOTOGRAPHER_REFERRAL,
    false
  )
  return (
    <>
      <ScrollToTop />
      <Switch>
        {routes.map((route) => (
          <Route
            render={(props) => {
              const Component = route.component
              return <Component {...props} />
            }}
            exact={route.exact}
            path={route.path}
            key={route.path}
          />
        ))}
        {/*This route is separate because it matches everything else.*/}
        {photographerReferralEnabled && (
          <Route
            render={(props) => {
              const Component = MyRewards
              return <Component {...props} />
            }}
            exact={true}
            path={paths.MY_REWARDS}
            key={paths.MY_REWARDS}
          />
        )}
        {photographerProfileEnabled && (
          <Route
            render={(props) => {
              const Component = PhotographerProfile
              return <Component {...props} />
            }}
            exact={true}
            path={paths.PHOTOGRAPHER_PROFILE}
            key={paths.PHOTOGRAPHER_PROFILE}
          />
        )}

        <Redirect to="/" />
      </Switch>
    </>
  )
}

export default Router
