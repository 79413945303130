import React from 'react'
import SaleItem from './SaleItem'
import { Purchase } from '../../../shared/models/Purchase'
import InfiniteScroll from 'react-infinite-scroll-component'
import { observer } from 'mobx-react'
import PurchaseStore from 'containers/Purchases/PurchaseStore'
import ConfigService, { ConfigKeys } from 'config'

type SaleItemsProps = {
  purchaseStore: PurchaseStore
  saleDesktopDetailsOpened: Purchase | null
  setSaleDesktopDetailsOpened: React.Dispatch<React.SetStateAction<Purchase | null>>
}

const SaleItems = ({
  purchaseStore,
  saleDesktopDetailsOpened,
  setSaleDesktopDetailsOpened,
}: SaleItemsProps) => {
  const fetchNextPage = () => {
    purchaseStore.fetchNextPage('sales')
  }

  return (
    <div className="rounded-b-lg md:overflow-y-auto md:max-h-[70vh]" id="infiniteScrollDiv">
      <InfiniteScroll
        dataLength={purchaseStore.userPurchases.length}
        next={fetchNextPage}
        hasMore={purchaseStore.hasMorePages()}
        loader={<></>}
        scrollThreshold={ConfigService.getValue(ConfigKeys.SCROLL_THRESHOLD)}
        scrollableTarget="infiniteScrollDiv"
      >
        {purchaseStore.userPurchases.map((sale, index) => (
          <SaleItem
            key={index}
            sale={sale}
            index={purchaseStore.purchaseCount - index}
            saleDesktopDetailsOpened={saleDesktopDetailsOpened}
            setSaleDesktopDetailsOpened={setSaleDesktopDetailsOpened}
          />
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default observer(SaleItems)
