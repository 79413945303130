import React, { useEffect, useState, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import Checkbox from 'components/UI/Checkbox'
import SkeletonLoaderPhotographCard from 'components/UI/Loaders/Skeleton/SkeletonLoaderPhotographCard'
import { PhotographUtils } from 'shared/util/photographs.utils'
import {
  calculateTotalPhotographsPrice,
  formatPhotographCountText,
  formatUtcToLocaleDate,
  getFeatureFlagValue,
  getQtyDiscountPercentageEarned,
} from 'shared/utility'
import AlbumDetailsStore from '../AlbumDetailsStore'
import { Photograph } from 'shared/models/Photograph'
import AlbumDetailsNoResult from 'containers/MyAlbums/AlbumDetails/components/AlbumDetailsNoResult'
import PhotographModal from 'components/PhotographDetailsModal/PhotographModal'
import FaceRecognitionModal from './FaceRecognitionModal'
import { observer } from 'mobx-react'
import Spinner from 'components/UI/Spinner'
import { feedAlbumDetailById, feedAlbumPhotographDetailById } from 'routing/Paths/feed'
import ItemDetailsFiltersAccordion from 'components/ItemDetails/components/ItemDetailsFiltersAccordion'
import { useHistory } from 'react-router'
import TagFilterModal from './TagFilterModal'
import DateAndTimeFilterModal from './DateAndTimeFilter/DateAndTimeFilterModal'
import ItemDetailsSearchResultMessage from 'components/ItemDetails/components/ItemDetailsSearchResultMessage'
import ItemDetailsInformation from 'components/ItemDetails/components/ItemDetailsInformation'
import { eachDayOfInterval } from 'date-fns'
import Masonry from '@mui/lab/Masonry'
import GallerySinglePhoto from 'components/UI/DetailsPhotoGallery/GallerySinglePhoto'
import DetailsPhotographCard from './DetailsPhotographCard'
import ItemDetailsDiscountAccordion from 'components/ItemDetails/components/ItemDetailsDiscountAccordion'
import { FeatureFlags } from 'config/constants/featureFlags'
import storesContext from 'providers/storesContext'
import BurstButton from 'components/UI/Button/BurstButton'
import Tracker from 'shared/tracking'
import { getURLWithTagFilters } from '../../ItemDetails/utils/ItemDetailsUtils'
import AlbumInformationSkeleton from './SkeletonLoader/AlbumInformationSkeleton'
import ConfigService, { ConfigKeys } from 'config'

type AlbumDetailsContentProps = {
  initialRender: React.MutableRefObject<boolean>
  albumDetailsStore: AlbumDetailsStore
  photographId: string
  isBurstMode: boolean
  handleBurstModeChange: (check: boolean) => void
  fetchNextPage: () => void
  downloadFreePhotograph: (photographId: string) => void
  setShowBuyPackageButton: React.Dispatch<React.SetStateAction<boolean>>
}

const AlbumDetailsContent = ({
  initialRender,
  albumDetailsStore,
  photographId,
  isBurstMode,
  handleBurstModeChange,
  fetchNextPage,
  downloadFreePhotograph,
  setShowBuyPackageButton,
}: AlbumDetailsContentProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [photographToDisplayId, setPhotographToDisplayId] = useState<string | null>(null)
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true)
  const [searchInProgress, setSearchInProgress] = useState(false)
  const [showUploadSelfieModal, setShowUploadSelfieModal] = useState(false)
  const [showFilterByTagModal, setShowFilterByTagModal] = useState(false)
  const [showFilterByDateAndTimeModal, setShowFilterByDateAndTimeModal] = useState(false)

  const { cartStore, featureFlagsStore } = useContext(storesContext)!

  const { album, photographs } = albumDetailsStore

  const burstPurchaseFeatureFlag = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.BUY_BURSTS_PACKAGE,
    false
  )

  useEffect(() => {
    setPhotographToDisplayId(photographId)
  }, [photographId, albumDetailsStore.isAuthenticated])

  useEffect(() => {
    if (!albumDetailsStore.isLoadingAlbumPhotographs) {
      const index = albumDetailsStore.photographs.findIndex(
        (photograph) => photograph.id === photographToDisplayId
      )
      if (index === -1) {
        setPhotographToDisplayId(null)
      } else if (
        albumDetailsStore.photographs.length > 1 &&
        albumDetailsStore.photographs.length - 1 === index
      ) {
        albumDetailsStore.fetchNextPage()
      }
    }
    const handlePopState = () => {
      if (photographToDisplayId) {
        closeImageModal()
      }
    }

    window.addEventListener('popstate', handlePopState)
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [photographToDisplayId])

  useEffect(() => {
    if (albumDetailsStore.tagValue.value || albumDetailsStore.isFilteringByFaceRecognition) {
      setSearchInProgress(true)
    }
  }, [])

  const setPhotographModalPhotograph = (albumId: string, photographId: string) => {
    const currentUrl = window.location.href
    window.history.pushState(
      { previousUrl: currentUrl },
      '',
      feedAlbumPhotographDetailById(albumId, photographId)
    )
    setPhotographToDisplayId(photographId)
  }

  const setIntervalAccordionPhotographModalPhotograph = (photographId: string) => {
    const currentUrl = window.location.href
    window.history.pushState(
      { previousUrl: currentUrl },
      '',
      feedAlbumPhotographDetailById(albumDetailsStore.album.id, photographId)
    )
    setPhotographToDisplayId(photographId)
  }

  const closeImageModal = () => {
    setPhotographToDisplayId(null)
    const initialUrl = feedAlbumDetailById(albumDetailsStore.album!.id)

    const params = new URLSearchParams()
    if (albumDetailsStore.tagValue.value) {
      params.set('tagValue', albumDetailsStore.tagValue.value)
    }

    const queryString = params.toString()
    const finalUrl = queryString ? `${initialUrl}?${queryString}` : initialUrl

    if (!window.history.state?.isClosing) {
      window.history.pushState({ isClosing: true }, '', finalUrl)
    }

    if (albumDetailsStore.photographs.length === 1) {
      albumDetailsStore.fetchPhotographs(true)
    }
    PhotographUtils.mantainScrollPositionAfterClosingModal(
      photographToDisplayId,
      albumDetailsStore.photographs
    )
  }

  const updateURLWithFilters = () => {
    const updatedURL = getURLWithTagFilters(albumDetailsStore.tagValue, location)
    history.replace(updatedURL)
  }

  const handleClearFilters = () => {
    initialRender.current = false
    albumDetailsStore.setTagValue('')
    albumDetailsStore.setDateRange([null, null])
    setShowBuyPackageButton(false)
    albumDetailsStore.setIsFilteringByFaceRecognition(false)
    albumDetailsStore.fetchPhotographs(true)
    albumDetailsStore.clearPhotographCount()
    updateURLWithFilters()
    setSearchInProgress(false)
    setIsAccordionExpanded(true)
  }

  const filterByFaceRecognition = () => {
    initialRender.current = false
    albumDetailsStore.setTagValue('')
    albumDetailsStore.tagIds = []
    albumDetailsStore.setDateRange([null, null])
    albumDetailsStore.clearPhotographCount()
    albumDetailsStore.setIsFilteringByFaceRecognition(true)
    albumDetailsStore.fetchPhotographs(true)
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const filterByTagValue = () => {
    initialRender.current = false
    albumDetailsStore.clearPhotographCount()
    albumDetailsStore.setDateRange([null, null])
    albumDetailsStore.fetchPhotographs(true, albumDetailsStore.tagValue.value)
    setShowFilterByTagModal(false)
    updateURLWithFilters()
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const filterByDateAndTime = () => {
    initialRender.current = false
    albumDetailsStore.setTagValue('')
    albumDetailsStore.clearPhotographCount()
    albumDetailsStore.fetchPhotographs(true)
    setShowFilterByDateAndTimeModal(false)
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const albumDays = useMemo(() => {
    const firstDate = albumDetailsStore.album.firstPhotographDate
    const lastDate = albumDetailsStore.album.lastPhotographDate

    if (!firstDate || !lastDate) {
      return []
    }

    const start = new Date(firstDate)
    const end = new Date(lastDate)

    if (end <= start) {
      return []
    }

    return eachDayOfInterval({ start, end }).map((date) => ({
      value: date.toISOString(),
      label: formatUtcToLocaleDate(date),
    }))
  }, [albumDetailsStore.album.firstPhotographDate, albumDetailsStore.album.lastPhotographDate])

  const moreThanFiveDays = useMemo(() => {
    const firstDate = albumDetailsStore.album.firstPhotographDate
      ? new Date(albumDetailsStore.album.firstPhotographDate)
      : null
    const lastDate = albumDetailsStore.album.lastPhotographDate
      ? new Date(albumDetailsStore.album.lastPhotographDate)
      : null
    return firstDate && lastDate
      ? (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24) > 5
      : false
  }, [albumDetailsStore.album.firstPhotographDate, albumDetailsStore.album.lastPhotographDate])

  const renderFilterAccordion = useMemo(
    () =>
      albumDetailsStore.album.faceRecognitionProcessed ||
      (!isNil(albumDetailsStore.albumTags) && albumDetailsStore.albumTags?.length > 0) ||
      !moreThanFiveDays,
    [
      albumDetailsStore.album.faceRecognitionProcessed,
      albumDetailsStore.albumTags,
      moreThanFiveDays,
    ]
  )

  const photographBlocksByBurst = isBurstMode
    ? PhotographUtils.groupByBursts(photographs, 3000)
    : new Map([['All', photographs]])

  const handleAddBurst = (burst: Photograph[]) => {
    cartStore.addAlbumBurst(album, burst)
  }

  const handleBuyBurst = async (burst: Photograph[]) => {
    Tracker.addPackagePaymentInformation(photographs, album)
    const purchase = await cartStore.createBurstPurchase(album.currency, [album], burst)
    if (!isNil(purchase)) {
      window.location.href = purchase.paymentUrl!
    }
  }

  return (
    <>
      <div className="mb-3 rounded-md bg-white">
        {!albumDetailsStore.isLoadingAlbumDetails && (
          <>
            <ItemDetailsInformation
              album={albumDetailsStore.album}
              isLoading={albumDetailsStore.isLoadingAlbumDetails}
            />
            {!isNil(albumDetailsStore.album.event.id) && (
              <div className="px-4 py-2 text-sm text-lumepic-grey border-t border-lumepic-light_grey">
                {t('This album is part of the event')}{' '}
                <span className="font-bold">{albumDetailsStore.album.event.name}</span>
              </div>
            )}
          </>
        )}
      </div>
      {!isNil(albumDetailsStore.album.quantityDiscount) &&
        !albumDetailsStore.isLoadingAlbumDetails && (
          <ItemDetailsDiscountAccordion album={albumDetailsStore.album} />
        )}
      {renderFilterAccordion && !albumDetailsStore.isLoadingAlbumDetails && (
        <div className="w-full mb-4">
          <ItemDetailsFiltersAccordion
            isAccordionExpanded={isAccordionExpanded}
            setIsAccordionExpanded={setIsAccordionExpanded}
            albumDetailsStore={albumDetailsStore}
            handleClearFilters={handleClearFilters}
            handleOpenUploadSelfieModal={() => setShowUploadSelfieModal(true)}
            handleOpenFilterByTagModal={() => setShowFilterByTagModal(true)}
            handleOpenFilterByDateAndTimeModal={() => setShowFilterByDateAndTimeModal(true)}
            searchInProgress={searchInProgress}
            moreThanFiveDays={moreThanFiveDays}
            days={albumDays.length > 0 ? albumDays : undefined}
          />
        </div>
      )}

      {burstPurchaseFeatureFlag && !albumDetailsStore.isLoadingAlbumDetails && (
        <div className="w-40 bg-white rounded-md shadow-md">
          <Checkbox
            id="burst-mode"
            label={t('View bursts')}
            value={isBurstMode}
            onChange={handleBurstModeChange}
          />
        </div>
      )}

      <ItemDetailsSearchResultMessage
        searchInProgress={searchInProgress}
        tagValueLength={0}
        isFilteringByFaceRecognition={false}
        isLoadingEventPhotographs={false}
        photographCount={0}
      />
      {albumDetailsStore.isFilteringByFaceRecognition &&
        !albumDetailsStore.isLoadingAlbumPhotographs && (
          <div className="mx-2 mt-4">
            {t('You’ve appeared in') +
              ' ' +
              formatPhotographCountText(albumDetailsStore.photographCount, t)}
            !
          </div>
        )}
      {!albumDetailsStore.isLoadingAlbumPhotographs &&
        !albumDetailsStore.isLoadingAlbumDetails &&
        (!albumDetailsStore || albumDetailsStore.photographs.length === 0) && (
          <AlbumDetailsNoResult album={albumDetailsStore.album} />
        )}
      {!albumDetailsStore.isLoadingAlbumDetails && albumDetailsStore.photographs.length > 0 && (
        <InfiniteScroll
          dataLength={albumDetailsStore.photographs.length}
          next={fetchNextPage}
          hasMore={albumDetailsStore.hasMorePages()}
          loader={<></>}
          scrollThreshold={ConfigService.getValue(ConfigKeys.SCROLL_THRESHOLD)}
          className="overflow-hidden"
        >
          <div className="w-full min-h-primaryMobile bg-white rounded-lg mt-2 p-2">
            {album.isFree ? (
              <Masonry columns={{ xs: 2, sm: 3, md: 4 }} spacing={0.5} className="w-full">
                {photographs.map((photograph) => (
                  <GallerySinglePhoto
                    key={photograph.id}
                    photograph={photograph}
                    setPhotograph={setIntervalAccordionPhotographModalPhotograph}
                    downloadFreePhotograph={downloadFreePhotograph}
                    store={albumDetailsStore}
                  />
                ))}
              </Masonry>
            ) : (
              <>
                <div className="w-full grid grid-cols-6 gap-2 mt-2 px-1.5">
                  {Array.from(photographBlocksByBurst.entries()).map(
                    ([burstKey, burstPhotographs], index) => (
                      <div
                        key={burstKey}
                        className={`col-span-6 ${
                          isBurstMode && 'rounded-md border border-lumepic-medium_grey'
                        }`}
                      >
                        {isBurstMode && (
                          <div className="w-full flex justify-between items-center py-3 px-2 bg-bg_details rounded-t-md">
                            <h6 className="font-medium">
                              {t('Burst {{number}}', { number: index + 1 })}
                            </h6>
                            {burstPurchaseFeatureFlag &&
                              !isNil(album.burstPrice) &&
                              (() => {
                                const totalPrice = calculateTotalPhotographsPrice(
                                  burstPhotographs[0].price,
                                  burstPhotographs.length
                                )
                                const discountPercentage = getQtyDiscountPercentageEarned(
                                  album.quantityDiscount ?? null,
                                  burstPhotographs.length
                                )
                                const priceAfterDiscount =
                                  totalPrice * (1 - discountPercentage / 100)

                                return priceAfterDiscount > album.burstPrice! ? (
                                  <BurstButton
                                    handleAddBurstClick={handleAddBurst}
                                    handleBuyBurst={handleBuyBurst}
                                    burstPhotographs={burstPhotographs}
                                    albumDetailsStore={albumDetailsStore}
                                  />
                                ) : null
                              })()}
                          </div>
                        )}
                        <div className={`grid grid-cols-6 gap-2 ${isBurstMode && 'p-1.5'}`}>
                          {burstPhotographs.map((photograph) => {
                            return (
                              <DetailsPhotographCard
                                photograph={photograph}
                                album={album}
                                albumDetailsStore={albumDetailsStore}
                                setPhotograph={setIntervalAccordionPhotographModalPhotograph}
                                key={photograph.id}
                                downloadFreePhotograph={downloadFreePhotograph}
                              />
                            )
                          })}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </>
            )}
          </div>
        </InfiniteScroll>
      )}
      {albumDetailsStore.isLoadingAlbumPhotographs && albumDetailsStore.page > 1 && (
        <Spinner divStyles="flex justify-center mt-5 items-center" size={40} />
      )}
      {albumDetailsStore.isLoadingAlbumDetails && albumDetailsStore.page === 1 && (
        <AlbumInformationSkeleton />
      )}
      {albumDetailsStore.isLoadingAlbumPhotographs && albumDetailsStore.page === 1 && (
        <SkeletonLoaderPhotographCard />
      )}
      {!isNil(photographToDisplayId) && (
        <PhotographModal
          photographToDisplayId={photographToDisplayId}
          setPhotographToDisplayId={setPhotographModalPhotograph}
          album={albumDetailsStore.album}
          photographs={albumDetailsStore.photographs}
          loadingNextPage={albumDetailsStore.isLoadingAlbumPhotographs}
          onClose={closeImageModal}
          onFeed
          onDownloadFreePhotograph={downloadFreePhotograph}
          isLoadingFreePhotograph={albumDetailsStore.isLoadingFreePhotograph}
        />
      )}
      <FaceRecognitionModal
        opened={showUploadSelfieModal}
        onCancel={() => setShowUploadSelfieModal(false)}
        store={albumDetailsStore}
        onSuccess={() => filterByFaceRecognition()}
      />
      <TagFilterModal
        opened={showFilterByTagModal}
        onCancel={() => setShowFilterByTagModal(false)}
        store={albumDetailsStore}
        onSuccess={() => filterByTagValue()}
        handleClearFilters={() => handleClearFilters()}
      />
      <DateAndTimeFilterModal
        opened={showFilterByDateAndTimeModal}
        onCancel={() => setShowFilterByDateAndTimeModal(false)}
        days={albumDays}
        store={albumDetailsStore}
        onSuccess={() => filterByDateAndTime()}
        handleClearFilters={() => handleClearFilters()}
      />
    </>
  )
}

export default observer(AlbumDetailsContent)
